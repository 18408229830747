<template>
  <section class="mx-auto">
    <div class="py-4" />
    <b-overlay :show="loading">
      <div>
        <div v-if="metaData">
          <h5>{{ metaData.name }}</h5>
          <div>
            <small>{{ new Intl.DateTimeFormat('ko-KR', { dateStyle: 'full', timeStyle: 'long' }).format(new Date((metaData.creationTimestamp))) }}</small>
          </div>
          <div class="d-flex justify-end align-items-end justify-content-end">
            <b-button
              :variant="toggle ? 'outline-primary' : 'primary'" @click="toggle = !toggle" size="sm">
              <b-icon icon="info-circle" />
            </b-button>
          </div>
          <b-collapse :visible="toggle">
            <vue-json-pretty
              :data="metaData"
            />
          </b-collapse>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { PodOne, Metadata } from '@/interface/admin/k8s/pod.one';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sPodSpecific extends Vue {
  private loading = false;
  private toggle = false;
  private metaData: Metadata | null = null;
  //
  get namespace () {
    return this.$route.params.namespace;
  }

  get deployment () {
    return this.$route.params.deployment;
  }

  get pod () {
    return this.$route.params.pod;
  }

  get metaDataString() {
    return this.metaData ? '' : ''
  }

  private async created() {
    this.load();
  }

  private async podInfo() {
    const url = `/admin/k8s/pod/${ this.namespace }/${ this.deployment }/${ this.pod }`;
    const { data: { body: { metadata } } } =await this.axios.get<PodOne>(url);
    return metadata;
  }

  private async load() {
    try {
      this.loading = true;
      this.metaData = await this.podInfo();
    } finally {
      this.loading = false;
    }
  }

}

</script>

<style scoped>
section {
  max-width: 960px;
}
</style>
