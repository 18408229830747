<template>
  <b-modal id="board-remove-modal" size="sm" class="w-50" hide-footer hide-header centered>
    <section class="w-100 text-center">
      <uni-font color="textTitle" weight="semiBold" size="15">해당 게시물을 삭제하시겠습니까?</uni-font>
      <uni-font color="placeholder" size="14">삭제 후 복구는 불가능 합니다.</uni-font>
    </section>
    <section class="w-100 d-flex mt-4">
      <uni-button
        variant="secondary"
        class="custom-mr-10"
        :click="cancel"
      >
        <uni-font color="placeholder" size="15" weight="semiBold">취소</uni-font>
      </uni-button>
      <uni-button
        variant="red"
        class="custom-mr-10"
        :click="remove"
      >
        <uni-font color="white" size="15" weight="semiBold">삭제</uni-font>
      </uni-button>
    </section>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class BlogDeleteModal extends Vue {
  @Prop() listId?: string;

  async remove() {
    try {
      const { data } = await this.axios.put(`/admin/board/blog/remove/${this.listId}`);
      const { result } = data;
      if (result) {
        this.$common.makeToast(ToastMessage.DELETE, ToastVariant.DANGER, this.$bvToast);
        this.$emit('toList');
      }
    } catch (e) {
      console.log(e);
    }
  }
  cancel() {
    this.$bvModal.hide('board-remove-modal');
  }
}
</script>

<style scoped>
.button-height {
  height: 40px;
}
</style>
