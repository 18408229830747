<template>
  <div class="h-100">
    <article class="bg-index-gradient custom-pt-200">
      <form class="login-form" @submit.prevent="login">
        <section class="login-title">
          <img width="268" src="@/assets/logos/logo-white-removebg-preview.png" alt="surveymate" loading="eager" />
          <uni-font weight="bold" size="24" class="w-100 text-center">관리자 로그인</uni-font>
        </section>
        <section class="login-input-wrapper custom-mt-20" :class="idFocus ? idActive : ''">
          <id-icon class="custom-ml-10" :id-focus="idFocus" />
          <input
            placeholder="아이디"
            @focusin="idFocus = true"
            @focusout="idFocus = false"
            v-model="loginInfo.userId"
            class="text_label login_placeholder"
            :class="idFocus ? idFocusInput : ''"
            type="text"
            tabindex="1"
          />
        </section>
        <section class="login-input-wrapper custom-mt-10" :class="passwordFocus ? passwordActive : ''">
          <password-icon class="custom-ml-10" :password-focus="passwordFocus" />
          <input
            v-model="loginInfo.userPwd"
            placeholder="비밀번호"
            min="8"
            @focusin="passwordFocus = true"
            @focusout="passwordFocus = false"
            class="text_label login_placeholder"
            :class="passwordFocus ? pwFocusInput : ''"
            ref="passwordInput"
            type="password"
            autocomplete="off"
            tabindex="1"
          />
        </section>

        <section class="login-button-wrapper">
          <uni-button class="w-100 h-100" :disabled="failState || isLoading" @keydown.enter="login" type="submit">
            <uni-font color="white" weight="bold" size="18">로그인</uni-font>
          </uni-button>
        </section>

      </form>
    </article>
  </div>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { USER } from '@/types/user';
import { IUserLogin } from '@/interface/user/user';
import PasswordIcon from "@/components/icons/login/PasswordIcon.vue";
import IdIcon from "@/components/icons/login/IdIcon.vue";

@Component({
  components: {IdIcon, PasswordIcon}
})
export default class Login extends Vue {
  @Ref() passwordInput: HTMLElement;

  idFocus = false;
  passwordFocus = false;
  idActive = 'id_focus';
  passwordActive = 'password_focus';
  idFocusInput = 'id_focus_input';
  pwFocusInput = 'password_focus_input';

  loginInfo: IUserLogin;
  failCount: number;
  failFlag: string;
  isLoading = false;

  constructor() {
    super();
    this.loginInfo = USER.LoginInit();
    this.failCount = 0;
    this.failFlag = '';
  }

  async login(): Promise<void> {
    if (!this.idValidation()) return;
    if (!this.pwdValidation()) return;

    const sendData = this.createSendData();

    const { data } = await this.axios.post(`/admin/user/login`,sendData);
    const { result } = data;
    if (result) {
      await this.$store.dispatch('login', { data });
      await this.$router.push('/admin/list');
    } else {
      this.loginInfo.userPwd = '';
      this.failFlag = data.failFlag || '';
      if (this.failFlag == 'auth') {
        this.$toast.error(data.message || 'error!');
      }
      this.failCount++;
      this.failCheck();
    }
  }

  idValidation(): boolean {
    const isIdNull = !this.loginInfo.userId;
    if (isIdNull) {
      this.$toast.error('아이디를 입력해 주세요.');
      return false;
    }
    return true;
  }

  pwdValidation(): boolean {
    const isPwNull = !this.loginInfo.userPwd;
    if (isPwNull) {
      this.$toast.error('비밀번호를 입력해 주세요.');
      return false;
    }
    return true;
  }

  failCountInit(): void {
    this.failCount = 0;
  }

  failCheck(): boolean {
    const overTry = this.failCount >= 5;
    if (overTry) {
      setTimeout(this.failCountInit, 10000);
      return false;
    }
    return true;
  }

  createSendData(): IUserLogin {
    const { userId, userPwd } = this.loginInfo;
    return {
      userId,
      userPwd,
    };
  }

  get idState(): boolean | null {
    if (!this.loginInfo.userId) return null;
    return this.loginInfo.userId.length > 0;
  }

  get passwordState(): boolean | null {
    if (!this.loginInfo.userPwd) return null;
    return this.loginInfo.userPwd.length >= 8;
  }

  get failState(): boolean {
    return this.failCount >= 5;
  }

  focusPw() {
    this.passwordInput.focus();
  }
}
</script>
<style scoped lang="scss">
.login-form {
  margin: auto;
  align-items: center;
  background: $white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-height: 380px;
  width: 414px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.07);

  .login-title {
    background-color: #fafafa;
    border-radius: 18px 18px 0 0;
    height: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }

  .login-input-wrapper {
    border: 1px solid $line;
    border-radius: 5px;
    display: flex;
    width: 374px;
    height: 56px;
    align-items: center;
  }

  .login-description-wrapper {
    width: 374px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* input focus */
  .id_focus,
  .password_focus {
    border: 1px solid $point-color !important;
    padding: 0 !important;
  }

  .login_placeholder {
    padding: 13px 0 13px 18px;
    width: 100%;
    height: 100%;
    border: none;
  }

  .id_focus_input,
  .password_focus_input {
    color: $black !important;
  }

  .text_label {
    width: 80%;
    background: $white;
    border: none;
    outline: none;
    color: $textPlaceholder;
    font-family: $nanumGothic;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.64px;
    height: 100%;
  }

  .login-wording {
    font-family: $nanumGothic;
    font-size: 14px;
    color: $textPlaceholder;
    font-weight: 400;
    letter-spacing: -0.56px;
    line-height: 20px;
  }

  .login-anchor {
    color: $point-color;
    border-bottom: 1px solid $point-color;
  }

  .text-2 {
    color: transparent;
    font-family: $nanumGothic;
    font-size: 1em;
    letter-spacing: -0.64px;
    padding-top: 30px;
    padding-bottom: 36px;
    min-height: 18px;
    min-width: 190px;
  }

  /* 버튼 */
  .login-button-wrapper {
    height: 56px;
    width: 374px;
    margin-top: 16px;
  }

  .login-icons {
    left: 10px;
    position: relative;
  }

  .span0-2 {
    color: $textPlaceholder;
    font-weight: 400;
  }
}

</style>
