<template>
  <b-overlay spinner-variant="primary" :show="loading" rounded="sm">
    <b-table-simple
      responsive
    >
      <colgroup>
        <col width="15%"/>
        <col width="15%"/>
        <col width="15%"/>
        <col width="15%"/>
        <col width="15%"/>
        <col width="15%"/>
        <col width="15%" v-if="isPanel"/>
      </colgroup>
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th>
            <uni-font color="textBody" size="16" weight="semiBold">
              Quota
            </uni-font>
          </b-th>
          <b-th>
            <uni-font color="textBody" size="16" weight="semiBold">
              Current
            </uni-font>
          </b-th>
          <b-th>
            <uni-font color="textBody" size="16" weight="semiBold">
              Remain
            </uni-font>
          </b-th>
          <b-th>
            <uni-font color="textBody" size="16" weight="semiBold">
              Progress(%)
            </uni-font>
          </b-th>
          <b-th>
            <uni-font color="textBody" size="16" weight="semiBold">
              Feasible
            </uni-font>
          </b-th>
          <b-th v-if="isPanel">
            <uni-font color="textBody" size="16" weight="semiBold">
              Tools
            </uni-font>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="samplingList.length">
          <b-tr
              v-for="(item, idx) in samplingList"
              :key="idx"
          >
            <b-td>
              <uni-font color="textBody" size="16" weight="semiBold">
                {{ item.Label }}
              </uni-font>
            </b-td>
            <b-td>
              <uni-font color="textBody" size="16" weight="semiBold">
                {{ item.Quota }}
              </uni-font>
            </b-td>
            <b-td>
              <uni-font color="textBody" size="16" weight="semiBold">
                {{ item.Current }}
              </uni-font>
            </b-td>
            <b-td>
              <uni-font color="textBody" size="16" weight="semiBold">
                {{ item.Remain }}
              </uni-font>
            </b-td>
            <b-td>
              <uni-font color="textBody" size="16" weight="semiBold">
                {{ item.Progress }}
              </uni-font>
            </b-td>
            <b-td>
              <uni-font color="textBody" size="16" weight="semiBold">
                {{ item.Feasible }}
              </uni-font>
            </b-td>
            <b-td v-if="isPanel">
              <uni-button
                variant="option"
                :click="() => sampling(idx)"
                :disabled="!inProgress"
              >
                <uni-font color="placeholder">
                  발송
                </uni-font>
              </uni-button>
            </b-td>
          </b-tr>
          </template>
          <template v-else>
          <b-tr>
            <b-td colspan="9" class="text-center payment-table-text nanumgothic-normal-granite-gray-16px">
              검색된 프로젝트 리스트가 없습니다
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </b-overlay>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";
import {ToastMessage, ToastVariant} from "@/utils/ToastEnum";

interface SamplingList {
  Label: string;
  Quota: number;
  Current: number;
  Remain: number;
  Progress: number;
  Feasible: number;
}

@Component({})
export default class Sampling extends Vue{
  @Prop() loading: boolean;
  @Prop() snum: number;
  @Prop() inProgress: boolean;
  @Prop() isPanel: boolean;
  samplingList: SamplingList[] = [];


  mounted(){
    this.load();
  }

  async load(){
    const { data } = await this.axios.get<SamplingList[]>(`/admin/project/quota/table/${this.snum}`);
    this.samplingList = data;
  }

  async sampling(quotaIdx: number) {
    const count = prompt('발송량');

    if (!count) return this.$common.makeToast('값을 입력해 주세요', ToastVariant.DANGER, this.$bvToast);

    if (count.replace(/\d/g, '').length) {
      return this.$common.makeToast('숫자만 입력 가능합니다.', ToastVariant.DANGER, this.$bvToast);
    }

    const max = this.samplingList[quotaIdx].Remain * 3;
    if (Number(count) > max) {
      return this.$common.makeToast(`최대 ${max}건 발송 가능합니다.`, ToastVariant.DANGER, this.$bvToast);
    }

    try {
      const { data } = await this.axios.post(
        `/admin/project/sampling/${this.snum}`,
        {
          quotaIdx,
          count,
        }
      );

      const { result } = data;

      if (result) {
        return this.$common.makeToast(`발송 완료`, ToastVariant.SUCCESS, this.$bvToast);
      }
    } catch (e) {
      console.log(e)
    }

  }
}

</script>