<template>
  <b-container class="mt-5">
    <b-skeleton type="input" class="mt-5 mb-5"/>

    <b-skeleton-table
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
    />

    <b-skeleton type="input" class="mt-5 mb-5"/>
  </b-container>
</template>

<script lang="ts">

import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class ProjectListSkeleton extends Vue{

}

</script>