<template>
  <section class="mx-auto">
    <router-view />
    <div class="py-4" />
    <b-overlay :show="loading">
      <div class="d-flex justify-content-between">
        <h5>Nodes</h5>
        <div>
          <b-button-group>
            <b-button variant="primary" size="sm" @click="load">
              <b-icon-arrow-clockwise />
            </b-button>
          </b-button-group>
        </div>
      </div>
      <div
          v-for="{ metadata, spec: { providerID, podCIDR }, status: { allocatable: { cpu, memory }, capacity: { cpu: cpuCount }, nodeInfo: { architecture, operatingSystem, osImage, kubeletVersion } } } in items" :key="metadata.uid"
          class="border-bottom d-flex flex-column justify-content-start align-content-start align-items-start p-2"
      >
        <div class="flex-grow-1 d-flex justify-content-start">
          <router-link :to="{ path: `/admin/k8s/nodes/${ metadata.name }` }">
            {{ metadata.name }}
          </router-link>
        </div>
        <div class="py-1" />
        <div class="d-flex justify-content-between w-100">
          <b-badge variant="danger">
            {{ providerID }}
          </b-badge>

          <b-badge variant="danger">
            {{ podCIDR }}
          </b-badge>

          <b-badge variant="danger">
            <b-icon-cpu />
            {{ filterUsage(metadata.name).cpu }} / {{ cpu }} ({{ cpuCount }}core)
          </b-badge>

          <b-badge variant="danger">
            {{ nf.format(filterUsage(metadata.name).memory) }} / {{ nf.format(+memory.replace(/[^0-9]/g, '') / (1024 ** 2)) }}
          </b-badge>

          <b-badge variant="danger">
            {{ architecture }}
          </b-badge>

          <b-badge variant="danger">
            {{ osImage }}
          </b-badge>

          <b-badge variant="danger">
            {{ operatingSystem }}
          </b-badge>

          <b-badge variant="danger">
            kublet {{ kubeletVersion }}
          </b-badge>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Node, Item } from '@/interface/admin/k8s/node';
import { NodeUsage, Item as NodeUsageItem } from '@/interface/admin/k8s/node.usage';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sNodes extends Vue {

  private loading = false;
  private items: Item[] = [];
  private usageItems: NodeUsageItem[] = [];

  private get nf() {
    return new Intl.NumberFormat('en-US', { style: 'unit', unit: 'gigabyte', maximumFractionDigits: 1 })
  }

  async created() {
    this.load();
  }

  async load() {
    try {
      this.loading = true;
      this.items = await this.getNodes();
      this.usageItems = await this.getNodesUsage();
    } finally {
      this.loading = false;
    }
  }

  async getNodesUsage() {
    const url = `/admin/k8s/nodes/top`;
    const { data: { items } } = await this.axios.get<NodeUsage>(url);
    return items;
  }

  async getNodes() {
    const url = `/admin/k8s/nodes`;
    const { data: { body: { items } } } = await this.axios.get<Node>(url);
    return items;
  }

  private filterUsage(nodeName: string) {
    const find = this.usageItems.find(({ metadata: { name } }) => name === nodeName);
    return find ? {
      cpu: (+find.usage.cpu.replace(/[^0-9]/g, '') / (1024 ** 2)).toFixed(0) + 'm',
      memory: +find.usage.memory.replace(/[^0-9]/g, '') / (1024 ** 2)
    } : {
      cpu: `0m`,
      memory: 0
    };
  }
}

</script>

<style scoped>

</style>
