<template>
  <article class="sub-layout-body-container custom-mt-20">
    <section class="w-100">
       <h1 class="h5 user-setting-title-wrapper">
        <b-icon icon="list-ul" class="mr-2" />
        <span class="text nanumgothic-bold-fuscous-gray-20px">1:1 문의 내역</span>
      </h1>
      <hr class="hr" />
    </section>
    <section class="w-100">
      <b-row class="mt-3">
        <!-- <section class="help-board-top">
          <input
              class="search-bar-wrapper"
              type="text"
              @keyup.enter="load"
              @focusin="searchButtonHover = false"
              @focusout="searchButtonHover = true"
              v-model="search"
              placeholder="문의 검색"
          />
          <button type="button" class="search-button search-button-icon" @click="load">
            <img
                src="@/assets/images/icons/main/search-button.svg"
                @mouseover="activeSearchButton"
                alt="검색 버튼"
                v-if="searchButtonHover"
            />
            <img
                src="@/assets/images/icons/main/search-button-active.svg"
                @mouseleave="activeSearchButton"
                alt="검색 버튼"
                v-else
            />
          </button>
        </section> -->
        <b-col cols="12">
          <b-overlay spinner-variant="primary" :show="loading" rounded="sm">
            <b-table-simple class="w-100" hover responsive>
              <colgroup>
                <col width="10%" />
                <col width="50%" />
                <col width="15%" />
                <col width="10%" />
                <col width="15%" />
              </colgroup>
              <b-thead>
                <b-tr>
                  <b-th class="nanumgothic-normal-fuscous-gray-16px">유형</b-th>
                  <b-th class="nanumgothic-normal-fuscous-gray-16px">제목</b-th>
                  <b-th class="nanumgothic-normal-fuscous-gray-16px">문의메일</b-th>
                  <b-th class="nanumgothic-normal-fuscous-gray-16px">등록일</b-th>
                  <b-th class="nanumgothic-normal-fuscous-gray-16px">답변여부</b-th>
                </b-tr>
              </b-thead>
              <tbody v-if="loading">
                <tr v-for="i in 10" :key="i">
                  <th class="px-3"><b-skeleton /></th>
                  <th class="px-3"><b-skeleton /></th>
                  <th class="px-3"><b-skeleton /></th>
                  <th class="px-3"><b-skeleton /></th>
                  <th class="px-3"><b-skeleton /></th>
                </tr>
              </tbody>
              <b-tbody v-else>
                <template v-if="helpList.length > 0">
                  <b-tr
                    v-for="(list, index) in helpList"
                    :key="`list-${index}`"
                    class="pointer"
                    @click="questionView(index)"
                  >
                    <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">
                      <b-badge variant="primary" class="ml-3">{{ list.TYPE || '-' }}</b-badge>
                    </b-td>
                    <b-td class="nanumgothic-normal-granite-gray-16px"
                      ><span class="help-items">{{ list.TITLE }}</span></b-td
                    >
                    <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">
                      <small>{{ list.USER_ID.toLowerCase() }}</small></b-td
                    >
                    <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">{{ list.REG_DATE }}</b-td>
                    <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">
                      <b-badge :variant="statusColor(list.STATUS)">
                        {{ list.STATUS }}
                      </b-badge>
                    </b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td colspan="5" class="text-center payment-table-text nanumgothic-normal-granite-gray-16px">
                      등록된 1:1문의가 없습니다
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>

            <b-col cols="12" class="help-board-footer">
<!--              <b-pagination-nav
                  use-router
                  align="center"
                  v-model="page"
                  :number-of-pages="numberOfPages"
                  :limit="7"
                  :link-gen="linkGen"
              ></b-pagination-nav>-->
              <b-pagination
                  align="center"
                  v-model="page"
                  :total-rows="totalRows"
                  :per-page="10"
                  @input="load"
              ></b-pagination>

            </b-col>
          </b-overlay>
        </b-col>
      </b-row>
    </section>
  </article>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import { Dictionary } from 'vue-router/types/router';


@Component ({
  components: {}
})
export default class QuestionInfo extends Vue {
  page = 1;
  totalRows = 0;
  userId: string = this.$route.params.id;
  loading = false;
  helpList = [];
  search = '';
  searchButtonHover = true;

  created(){
    this.init();
    this.load();
  }
  init() {
    const { questionPage } = this.$route.query as Dictionary<string>;

    if (questionPage) this.page = +questionPage;
  }

  linkGen(questionPage: number) {
    return this.makeQuery({ questionPage });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp).map(entry => entry.join('=')).join('&');

    return `?${query}`;
  }

  private async load(){

    this.loading = true;

    try {
      const { data } = await this.axios.get(`/admin/getQuestionList/${this.userId}/${this.page}`);

      const { list, totalRows } = data;
      this.helpList = list;
      this.totalRows = totalRows;
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  statusColor(status: string): string {
    if (status === '확인중') return 'warning';
    if (status === '답변완료') return 'success';
    return 'info';
  }

  questionView(index: number) {
    const list = this.helpList[index];
    const { _id } = list;

    this.$router.push(`/admin/user/question/${_id}`);
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  get isProd() {
    return process.env.NODE_ENV === 'production'
  }

  get host() {
    return this.isProd ? `https://unisurvey.co.kr` : `http://localhost:8080`
  }
}
</script>

<style scoped>
.help-board-top {
  width: 100%;
  text-align: right;
  padding-bottom: 15px;
}
.help-board-footer {
  height: 50px;
  text-align: center;
}
.payment-table {
  width: 100%;
  min-height: 130px;
}

.receipt-button {
  letter-spacing: -0.42px;
  min-height: 16px;
}

.receipt-button:hover {
  color: #4d4949;
  text-underline: #a6a6a6;
  text-decoration: underline;
  text-underline-position: under;
  transition: width 0.5s, height 0.5s, background-color 0.5s, transform 0.5s;
}

.payment-status {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  justify-items: center;
}

.payment-table-text {
  letter-spacing: -0.48px;
  min-height: 18px;
  min-width: 45px;
  text-align: center;
  font-weight: 400;
}

/* 결제 상태에 따른 태그 */
.completePaymentLabel,
.cancelCompletePaymentLabel {
  border: 1px solid;
  min-width: 55px;
  min-height: 26px;
  text-align: center;
  border-radius: 17px;
  padding: 3px 8px;
}

.completePaymentLabel {
  border-color: var(--picton-blue);
  color: var(--picton-blue);
}

.cancelCompletePaymentLabel {
  border-color: var(--textplaceholder);
  color: var(--textplaceholder);
}
</style>
