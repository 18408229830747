<template>
  <article class="sub-layout-body-container custom-mt-20">
    <section class="user-setting-title-wrapper">
      <img src="@/assets/images/icons/users/manage-accounts.svg" class="custom-mr-10" alt="회원정보 수정" />
      <h1 class="nanumgothic-bold-fuscous-gray-20px">
        <span class="text-primary">{{ originName }}</span>님의 회원정보
      </h1>
    </section>
    <hr class="hr" />
    <section class="user-profile-container">
      <div class="user-profile-item-wrapper">
        <uni-font class="w-50" weight="bold" size="16" color="textTitle">회원ID</uni-font>
        <div class="d-flex w-100 justify-content-between">
          <uni-font class="w-100" size="15" color="textBody">{{userInfo._id.toLowerCase()}}</uni-font>
          <div class="block" />
        </div>
      </div>
      <div class="user-profile-item-wrapper">
        <uni-font class="w-50" weight="bold" size="16" color="textTitle">이름</uni-font>
        <div class="d-flex w-100 justify-content-between">
          <input class="user-profile-input" :disabled="updateState.name" type="text" v-model="userInfo.NAME" />
          <uni-button
            variant="option"
            width="60"
            :click="() => changeUpdateState('NAME')"
          >
            <uni-font weight="medium" color="placeholder" size="14">변경</uni-font>
          </uni-button>
        </div>

      </div>
      <div class="user-profile-item-wrapper">
        <uni-font class="w-50" weight="bold" size="16" color="textTitle">유형</uni-font>
        <div class="d-flex justify-content-between w-100">
          <select class="user-profile-input" :disabled="updateState.type" v-model="userInfo.USER_TYPE" >
            <option
              v-for="(select,index) of selectUserType"
              :key="index+'-TYPE'"
              :value="select.key"
            >
              <uni-font color="textSub">
                {{select.text}} <span v-if='(select.key === userInfo.USER_TYPE && select.key !== "NORMAL")'>({{userInfo.ENTERPRISE}})</span>
              </uni-font>
            </option>
          </select>
          <uni-button
            variant="option"
            width="60"
            :click="() => changeUpdateState('TYPE')"
          >
            <uni-font weight="medium" color="placeholder" size="16">변경</uni-font>
          </uni-button>
        </div>
      </div>
      <div v-show="userInfo.USER_TYPE !== 'NORMAL'" class="user-profile-item-wrapper">
        <uni-font class="w-50" weight="bold" size="16" color="textTitle">소속</uni-font>
        <div class="d-flex justify-content-between w-100">
          <input class="user-profile-input" :disabled="updateState.userType" type="text" v-model="userInfo.ENTERPRISE" />
          <uni-button
            variant="option"
            width="60"
            :click="() => changeUpdateState('USER_TYPE')"
          >
            <uni-font weight="medium" color="placeholder" size="16">변경</uni-font>
          </uni-button>
        </div>
      </div>
      <div class="user-profile-item-wrapper">
        <uni-font class="w-50" weight="bold" size="16" color="textTitle">휴대폰번호</uni-font>
        <div class="d-flex justify-content-between w-100">
          <input class="user-profile-input" :disabled="updateState.phone" type="text" v-model="userInfo.PHONE" />
          <uni-button
            variant="option"
            width="60"
            :click="() => changeUpdateState('PHONE')"
          >
            <uni-font weight="medium" color="placeholder" size="16">변경</uni-font>
          </uni-button>
        </div>
      </div>
      <div class="user-profile-item-wrapper">
        <uni-font class="w-50" weight="bold" size="16" color="textTitle">마케팅동의여부</uni-font>
        <uni-font class="w-100" size="15" color="textBody">{{userInfo.MARKETING_AUTH ? '동의' : '미동의'}}</uni-font>
      </div>
      <div class="user-profile-item-wrapper">
        <uni-font class="w-50" weight="bold" size="16" color="textTitle">회원등급</uni-font>
        <div class="d-flex justify-content-between w-100">
          <select class="user-profile-input" :disabled="updateState.rating" v-model="userInfo.USER_RATING">
            <option
              v-for="(select, index) of selectUserRating"
              :key="index+'-RATING'"
              :value="select.key"
            >
              <uni-font size="14" color="textSub">{{select.text}}</uni-font>
            </option>
          </select>
          <uni-button
            variant="option"
            width="60"
            :click="() => changeUpdateState('RATING')"
          >
            <uni-font weight="medium" color="placeholder" size="16">변경</uni-font>
          </uni-button>
        </div>
      </div>
      <div class="user-profile-item-wrapper">
        <uni-font class="w-50" weight="bold" size="16" color="textTitle">회원상태</uni-font>
        <div class="d-flex justify-content-between w-100">
          <select class="user-profile-input" :disabled="updateState.use" v-model="userInfo.USE">
            <option
              v-for="(select, index) of selectRegistState"
              :key="index+`-REGIST`"
              :value="select.key"
            >
              <uni-font size="14" color="textSub">{{select.text}}</uni-font>
            </option>
          </select>
          <uni-button
            variant="option"
            width="60"
            :click="() => changeUpdateState('USE')"
          >
            <uni-font weight="medium" color="placeholder" size="16">변경</uni-font>
          </uni-button>
        </div>
      </div>
    </section>
    <section class="w-100 d-flex justify-content-end" >
      <uni-button
        variant="secondary-outline"
        :click="updateUserInfo"
        :disabled="pending"
      >
        <uni-font weight="medium" color="placeholder" size="16">변경 사항 저장</uni-font>
      </uni-button>
    </section>
  </article>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ChangeNickname from '@/views/user/component/ChangeNickname.vue';
import {UserInfo} from "@/interface/user/user";
import {USER_RATING, USER_TYPE} from "@/enum/user";

interface SelectBox {
  key: string | boolean,
  text: string
}

interface UpdateState {
  name: boolean,
  type: boolean,
  phone: boolean,
  rating: boolean,
  use: boolean,
  userType: boolean
}

@Component({
  components: { ChangeNickname },
})
export default class ProfileInfo extends Vue {

  originName = '';
  selectRegistState: SelectBox[] = [
    { key: true, text: '정상' },
    { key: false, text: '탈퇴' }
  ];

  selectUserRating: SelectBox[] = [
    { key: USER_RATING.BRONZE, text: '신규가입 회원' },
    { key: USER_RATING.SILVER, text: '일반회원' },
    { key: USER_RATING.GOLD, text: '우수회원' },
    { key: USER_RATING.VIP, text: '최우수회원' },
    { key: USER_RATING.PM, text: 'PM' },
  ];

  selectUserType: SelectBox[] = [
    { key: USER_TYPE.NORMAL, text: '개인' },
    { key: USER_TYPE.UNIVERSITY, text: '학교' },
    { key: USER_TYPE.COMPANY, text: '기업' },
  ]

  userInfo: UserInfo = {
    NAME: '',
    MARKETING_AUTH: false,
    PHONE: '',
    USER_TYPE: '',
    _id: '',
    DEPART_ID: '',
    EMAIL_AUTH: false,
    EMAIL_AUTH_DT: new Date(),
    FAVORITE: [],
    LAST_LOGIN_DT: new Date(),
    LAST_LOGIN_IP: '',
    NICKNAME: '',
    OBJECT_ID: '',
    PROJECT: [],
    USE: false,
    USER_RATING: '',
    REGIST_DT: new Date(),
    ENTERPRISE: '',
  };

  pending = true;

  updateState: UpdateState = {
    name: true,
    type: true,
    phone: true,
    rating: true,
    use: true,
    userType: true
  }

  async created() {
    await this.load();
  }

  async load() {
    try {
      const id = this.$route.params.id;
      const { data } = await this.axios.get(`/admin/user/${id}`);
      this.userInfo = data;
      this.originName = data.NAME
      this.pending = false;
    } catch (e) {
      console.log(e);
    }
  }

  stateReset() {
    this.updateState.name = true;
    this.updateState.type = true;
    this.updateState.phone = true;
    this.updateState.rating = true;
    this.updateState.use = true;
    this.updateState.userType = true;
  }

  async updateUserInfo() {
    try {
      this.pending = true;
      const {data} = await this.axios.patch('/admin/user/profile', this.userInfo);
      if (data) {
        alert('변경되었습니다.');
        this.stateReset();
        location.reload();
        // await this.load();
      }
      this.pending = false;
    } catch (e) {
      console.log(e);
    }
  }

  changeUpdateState(type: string): void {
    switch (type) {
      case 'NAME':
        this.updateState.name = !this.updateState.name;
        break;
      case 'TYPE':
        this.updateState.type = !this.updateState.type;
        break;
      case 'PHONE':
        this.updateState.phone = !this.updateState.phone;
        break;
      case 'RATING':
        this.updateState.rating= !this.updateState.rating;
        break;
      case 'USE':
        this.updateState.use = !this.updateState.use;
        break;
      case 'USER_TYPE':
        this.updateState.userType = !this.updateState.userType;
        break;
      default:
        break;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-profile-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-items: center;
  align-items: center;
  margin: auto;

  .block {
    display: inline-block;
    width: 60px;
  }

  .user-profile-item-wrapper {
    text-align: left;
    width: 70%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0;
  }

  .user-profile-input {
    outline: none;
    border: 1px solid $line;
    border-radius: 4px;
    padding: 0 10px 0;
    width: calc(100% - 70px);

    &:focus {
      border-color: $point-color;
    }

    &:disabled {
      background-color: $placeholder;
      color: $textPlaceholder;
    }
  }
}

</style>
