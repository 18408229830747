<template>
  <article
    v-if="messageBoxActive"
    v-click-outside="closeBox"
    class="message-box scroll-type scroll"
  >
    <div class="w-100">
      <uni-font v-if="projectList.length === 0" class="message">알림 메세지가 없습니다.</uni-font>
      <div v-else>
        <div
          v-for="(group, index) in projectList"
          :key='index'
          class="w-100"
        >
          <uni-font
            color="textSub"
            size="12"
            class="p-2"
          >
            <b>{{group.LIST.length}} 건</b>의 {{makeKeywordMessage(group._id)}} 프로젝트가 있습니다.
          </uni-font>
          <hr />
          <div
            v-for="question in group.LIST"
            :key="question.SNUM"
            class="message_wrapper"
          >
            <uni-button
              variant="icon-button"
              class="p-1 w-100"
              :click="() => redirectProject(question.SNUM)"
            >
              <uni-font class="w-100  text-left" color="textBody" size="13">
                {{question.SNUM}} - {{question.TITLE}}
              </uni-font>
            </uni-button>
          </div>
        </div>

      </div>
    </div>
  </article>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {QUESTION} from "@/types/question";

type List = {
  SNUM: number,
  TITLE: string,
}

interface ProjectList {
  _id: number,
  LIST: List[]
}

@Component
export default class AlertMessage extends Vue {
  @Prop({default: []}) projectList: ProjectList[];
  @Prop({default: false}) active: boolean;

  messageBoxActive = false;

  @Watch('active')
  messageBoxWatch() {
    this.messageBoxActive = this.active;
  }

  @Emit('messageBoxActive')
  closeBox() {
    return !this.active;
  }

  redirectProject(SNUM: number) {
    this.closeBox();
    this.$router.push(`/admin/list/${SNUM}`);
  }

  makeKeywordMessage(STATUS: number) {
    switch (STATUS) {
      case QUESTION.LINK_STATUS.REJECT:
        return '반려된'
      case QUESTION.LINK_STATUS.REVIEW_HOLD:
        return '검수 대기중인'
      default:
        return ''
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0;
}
.message-box {
  position: absolute;
  background-color: #fdfdfd;
  width: 440px;
  max-height: 250px;
  height: auto;
  border: 1px solid $line;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;

  .message_wrapper {
    width: 100%;
    text-align: left;
    padding: 4px 2px;
    border-bottom: 1px solid $line;
    cursor: pointer;

    &:hover {
      background-color: #f1f1f1;
    }

    .message {
      font-family: $nanumGothic;
      font-size: 14px;
      width: 100%;
      padding: 0 8px;
      color: $textBody;
    }
  }


}
</style>