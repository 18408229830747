<template>
  <div>
    <main class="sub-layout-container custom-pb-100">
      <article class="sub-layout-top-container">
        <section class="sub-items-title-wrapper">
          <span class="sub-items-title">1:1 문의</span>
        </section>

        <b-overlay wrap-tag="article" :show="showing" rounded="sm" class="sub-layout-body-container">
          <article class="w-100">
            <b-row class="mt-3">
              <section class="help-board-top d-flex align-items-center">
                <div class="ml-auto d-flex align-items-center">
                  <select
                    class="my-survey-select-box"
                    size="sm"
                    v-model="searchType"
                    @change="search = ''"
                  >
                    <option
                      v-for="(option, index) of searchOptions"
                      class="nanumgothic-bold-granite-gray-13px testCss"
                      :value="option.value"
                      :key="index"
                    >
                      {{ option.text }}
                    </option>
                  </select>

                  <div v-if="searchType === 'category'">
                    <b-form-select
                      class="status-selector"
                      v-model="search"
                      :options="categoryOptions"
                      @change="searchSubmit"
                      size="sm"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled>
                          카테고리를 선택해 주세요
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                  <div v-else>
                    <input
                      class="search-bar-wrapper"
                      type="text"
                      @keyup.enter="searchSubmit"
                      @focusin="searchButtonHover = false"
                      @focusout="searchButtonHover = true"
                      v-model="search"
                      placeholder="문의 검색"
                    />
                    <button type="button" class="search-button search-button-icon" @click="searchSubmit">
                      <img
                        src="@/assets/images/icons/main/search-button.svg"
                        @mouseover="activeSearchButton"
                        alt="검색 버튼"
                        v-if="searchButtonHover"
                      />
                      <img
                        src="@/assets/images/icons/main/search-button-active.svg"
                        @mouseleave="activeSearchButton"
                        alt="검색 버튼"
                        v-else
                      />
                    </button>
                  </div>
                </div>

              </section>

              <b-col cols="12">
                <help-list
                  :help-list="helpList"
                  :loading="loading"
                  @view="questionView"
                ></help-list>
                <b-col cols="12" class="help-board-footer">
                  <b-pagination-nav
                    use-router
                    align="center"
                    v-model="page"
                    :number-of-pages="numberOfPages"
                    :limit="7"
                    :link-gen="linkGen"
                  />
                </b-col>
              </b-col>
            </b-row>
          </article>
        </b-overlay>
      </article>
    </main>
    <Footer />
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import HelpList from "@/components/question/HelpList.vue";
import {Dictionary} from "vue-router/types/router";


@Component({
  components: { HelpList, }
})
export default class QuestionList extends Vue{
  showState = 1;
  showing = false;
  loading = false;
  page = 1;
  totalRow = 0;
  perPage = 10;
  userId = this.$route.params.id;
  helpList = [];
  searchButtonHover = true;
  search = '';
  searchType = 'userId';

  searchOptions=[
    { value: 'userId', text: '회원ID' },
    { value: 'title', text: '제목' },
    { value: 'content', text: '내용' },
    { value: 'category', text: '카테고리' },
  ]

  categoryOptions = [
    { value: '가입/회원정보 문의', text: '가입/회원정보 문의' },
    { value: '설문 진행 문의', text: '설문 진행 문의' },
    { value: '템플릿 문의', text: '템플릿 문의' },
    { value: '결제 문의', text: '결제 문의' },
    { value: '기타/오류', text: '기타/오류' },
  ];

  linkGen(page: number) {
    return this.makeQuery({ page });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp).map(entry => entry.join('=')).join('&');

    return `?${query}`;
  }

  changeFilter(filterValue: { [key: string]: string | number }) {
    const query = this.makeQuery({
      page: 1,
      ...filterValue
    })
    this.$router.push(query).catch(() => ({}));
  }

  searchSubmit() {
    this.changeFilter({
      search: this.search,
      searchType: this.searchType,
    })
  }

  created(){
    this.init();
    this.load();
  }

  init() {
    const { page, search, searchType  } = this.$route.query as Dictionary<string>;

    if (page) this.page = +page;
    if (search) this.search = search;
    if (searchType) this.searchType = searchType;
  }

  private async load(){
    this.loading = true;

    try {
      const params = {
        PAGE: this.page,
        SEARCH_WORD: this.search,
        searchType: this.searchType,
      };
      const { data } = await this.axios({
        url: '/board/QuestionList/GetList',
        method: 'GET',
        params,
      });
      const { result, list, count } = data;
      console.log(list);
      if (result === true) {
        this.helpList = list;
        this.totalRow = count;
      }
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  questionView(index: number) {
    const list = this.helpList[index];
    const { _id } = list;

    this.$router.push({
      path: `/admin/question/${_id}`,
      query: this.$route.query,
    });
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  get numberOfPages() {
    return Math.ceil(this.totalRow / this.perPage) || 1;
  }
}
</script>
<style>
.help-board-top {
    width: 100%;
    text-align: right;
    padding-bottom: 15px;
}
.help-board-footer {
    height: 50px;
    text-align: center;
}
.my-survey-select-box {
    color: var(--textbody);
    font-family: var(--font-family-nanum_gothic);
    font-size: var(--font-size-xs);
    font-weight: 700;
    letter-spacing: -0.42px;
    margin-top: -1px;
    margin-right: 20px;
    width: fit-content;
    text-align: center;
    height: 28px;
    border-radius: 17px;
    border: 1px solid;
    border-color: var(--line);
}
.my-survey-select-box:focus {
    border: 1px solid;
    border-color: var(--point-color);
}
.my-survey-select-box:hover {
    cursor: pointer;
}

.status-selector {
    width: 346px;
    height: auto;
    border-radius: 17px;
    padding: 6px 32px 6px 14px;
    margin-right: 23px;
}
</style>