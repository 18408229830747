<template>
  <div>
    <question-specific
      :board-question="boardQuestion"
      :board-question-list="boardQuestionList"
      :last-reply="lastReply"
      @toList="toList"
      @toReply="toReply"
      @remove="remove"
    />
    <board-delete-modal
      :list-id="listId"
      @toList="toList"
    />
    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import * as BOARD from "@/types/board";
import BoardDeleteModal from '@/components/modal/BoardDeleteModal.vue';
import QuestionSpecific from '@/components/question/QuestionSpecific.vue'

@Component({
  components: {
    BoardDeleteModal,
    QuestionSpecific,
  },
})
export default class HelpView extends Vue {
  lastReply = false;
  listId = '';
  loading = true;
  boardQuestion: BOARD.IBOARDQuestion = BOARD.boardQuestionInit();
  boardQuestionList: BOARD.IBOARDQuestion[] = [];

  created() {
    const { listId } = this.$route.params as { listId: string };
    this.listId = listId;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/QuestionList/Read/${this.listId}`);
      const { question } = data;
      this.boardQuestion = question[0];
      this.boardQuestionList = question;
      this.lastReply = this.boardQuestionList[this.boardQuestionList.length-1].ANSWER?true:false;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  statusColor(status: string): string {
    if ( status === BOARD.QUESTION_STATUS.CHECKING ) return 'warning';
    if ( status === BOARD.QUESTION_STATUS.COMPLETE ) return 'success';
    else return '';
  }

  toList() {
    return this.$router.push({ path: `/admin/user/${this.boardQuestion.USER_ID}`});
  }

  toReply(level) {
    return this.$router.push({ path: `/admin/user/question-reply/${this.listId}/${level}`});
  }

  remove(): void {
    this.$bvModal.show('board-remove-modal');
  }
}
</script>

<style scoped>
.question-title span {
  height: 21px;
}

</style>
