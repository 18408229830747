<template>
  <tbody>
  <b-tr>
    <b-td>
      <uni-font color="textTitle" size="14" weight="medium">성별</uni-font>
    </b-td>
    <b-td>
      <div class="mt-0 mr-2 mb-0 round-box">
        <div
          class="inner-box"
          :class="{contacted:quota.GENDER === '0'}"
        >전체</div>
        <div
          class="inner-box"
          :class="{contacted:quota.GENDER === '1'}"
        >남성</div>
        <div
          class="inner-box"
          :class="{contacted:quota.GENDER === '2'}"
        >여성</div>
      </div>
    </b-td>
  </b-tr>
  <b-tr>
    <b-td>
      <p class="mb-0 mt-0"><b>연령</b></p>
    </b-td>
    <b-td>
       <template v-if="quota.AGE_UNIT==='0'">
          <div class="mt-2 mr-2 mb-2 round-box">
            <div
              class="inner-box contacted"
            >전체</div>
          </div>
        </template>
        <template v-if="quota.AGE_UNIT==='1'">
          <div class="mt-2 mr-2 mb-2 round-box">
            <div
              class="inner-box"
              :class="{contacted:quotaAge('2',10)}"
            >20-29</div>
            <div
              class="inner-box"
              :class="{contacted:quotaAge('3',10)}"
            >30-39</div>
            <div
              class="inner-box"
              :class="{contacted:quotaAge('4',10)}"
            >40-49</div>
            <div
              class="inner-box"
              :class="{contacted:quotaAge('5',10)}"
            >50-59</div>
            <div
              class="inner-box"
              :class="{contacted:quotaAge('6',10)}"
            >60-69</div>
          </div>
        </template>

      <template v-else>
        <div class="mt-2 mr-2 mb-2 round-box">
          <div
            class="inner-box"
            :class="{contacted:quotaAge('2',5)}"
          >20-24</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('3',5)}"
          >25-29</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('4',5)}"
          >30-34</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('5',5)}"
          >35-39</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('6',5)}"
          >40-44</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('7',5)}"
          >45-49</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('8',5)}"
          >50-54</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('9',5)}"
          >55-59</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('10',5)}"
          >60-64</div>
          <div
            class="inner-box"
            :class="{contacted:quotaAge('11',5)}"
          >65-69</div>
        </div>
      </template>
    </b-td>
  </b-tr>
  <b-tr>
    <b-td>
      <p class="mb-0 mt-1"><b>지역</b></p>
    </b-td>
    <b-td>
        <template>
          <div class="mt-2 mr-2 mb-2 round-box">
            <div
            class="inner-box"
              :class="{contacted:quotaArea('4')}"
            > 전국</div>
            <div
            class="inner-box"
              :class="{contacted:quotaArea('1')}"
            >서울</div>
            <div
            class="inner-box"
              :class="{contacted:quotaArea('2')}"
            >인천/경기</div>
            <div
            class="inner-box"
              :class="{contacted:quotaArea('3')}"
            >지방 5대광역시</div>
          </div>
        </template>
    </b-td>
  </b-tr>
  <b-tr>
    <b-td>
      <p class="mb-0 mt-1"><b>샘플수</b></p>
    </b-td>
    <b-td>
      <p class="w-25 mb-0 mt-1">{{quota.RESPONSE_CNT}} 명</p>
    </b-td>
  </b-tr>
  </tbody>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class ProjectViewQuota extends Vue{
  @Prop() quota: any;
  selected;

  quotaAge(str, age){
    let result = false;
    if(age === 10){
      result = this.quota.AGE_10.includes(str);
    }else{
      result = this.quota.AGE_5.includes(str);
    }
    return result;
  }

  quotaArea(area){
    let result = false;
    this.quota.AREA.forEach((a)=>{
      if(a === area){
        result = true;
      }
    });
    return result;
  }




}
</script>

<style scoped lang="scss">
.round-box{
  display: flex;
  width: 100%;
  height: 45px;
  line-height: 45px;
  overflow-y: hidden;
  overflow-x: auto;
}

.inner-box{
  border: 1px solid $line;
  padding: 0 10px;
  background-color: $placeholder;
  min-width: 70px;
  width: fit-content;
  margin: 0 2px;
  border-radius: 5px;
  font-family: $nanumGothic;
  font-size: 15px;
}

.contacted{
  border-color: $point-color;
  background-color: #f5fbfd;
  color: $point-color;
  font-weight: bold;
}

</style>