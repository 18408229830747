<template>
  <section class="mx-auto">
    <router-view />
    <div class="py-4" />
    <b-overlay :show="loading">
      <div class="d-flex justify-content-between">
        <h5>Ingress</h5>
        <div>
          <b-button-group>
            <b-button variant="primary" size="sm" @click="load">
              <b-icon-arrow-clockwise />
            </b-button>
          </b-button-group>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
@Component({})
export default class K8sIngressSpecific extends Vue {
  private loading = false;
  get namespace() {
    return this.$route.params.namespace;
  }

  get ingress() {
    return this.$route.params.ingress;
  }

  async load() {
    console.log(1)
  }

}

</script>

<style scoped>

</style>
