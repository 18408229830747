<template>
  <div>
    <main class="sub-layout-container custom-pb-200">
      <article class="sub-layout-top-container">
        <section class="sub-items-title-wrapper">
          <h1 class="sub-items-title">블로그</h1>
        </section>

        <section class="sub-layout-body-container">
          <div class="w-100">
            <table class="table-container w-100">
              <tr>
                <th>
                  <uni-font color="textSub" size="14">
                    제목
                  </uni-font>
                </th>
                <td class="question-title">
                  <uni-font class="w-100 text-left" color="textTitle" size="14">{{ blogData.TITLE }}</uni-font>
                </td>
              </tr>
              <tr>
                <th>
                  <uni-font color="textSub" size="14">
                    카테고리
                  </uni-font>
                </th>
                <td class="text-left">
                  <uni-font class="w-100 text-left" color="textTitle" size="14">{{ blogData.CATEGORY }}</uni-font>
                </td>
              </tr>
              <!-- <tr v-if="blogData.FILES.length > 0">
                <th>
                  <uni-font color="textSub" size="14">
                    첨부파일
                  </uni-font>
                </th>
                <td class="text-left">
                  <uni-font>
                    <a :href="blogData.FILES[0].Location">{{blogData.FILES[0].name}}</a>
                  </uni-font>
                </td>
              </tr> -->
              <tr>
                <th>
                  <uni-font color="textSub" size="14">
                    내용
                  </uni-font>
                </th>
                <td class="question-content">
                  <div v-html="blogData.CONTENTS"></div>
                </td>
              </tr>
              <tr class="nanumgothic-normal-fuscous-gray-14px">
                <th>
                  <uni-font color="textSub" size="14">
                    썸네일
                  </uni-font>
                </th>
                <td class="text-left">
                  {{thumbnail}}
                </td>
              </tr>
            </table>
          </div>
          <div class="w-100 mt-3 d-flex justify-content-end">
            <uni-button
              variant="red"
              :click="remove"
              class="custom-mr-16"
            >
              <uni-font color="white" size="14">삭제</uni-font>
            </uni-button>
            <uni-button
              :click="() => $router.push({name: 'blog-list'})"
              variant="secondary-outline"
              class="custom-mr-16"
            >
              <uni-font color="placeholder" size="14">목록</uni-font>
            </uni-button>

            <uni-button
              v-if="blogData.REGIST_ID === $store.getters.userId"
              :click="goEdit"
            >
              <uni-font color="white" size="14">수정</uni-font>
            </uni-button>
          </div>
        </section>
      </article>
    </main>
    <blog-delete-modal :list-id="blogId" @toList="$router.push({name: 'blog-list'})"/>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {helpInit, IHelp} from '@/types/board';
import BlogDeleteModal from "@/components/modal/BlogDeleteModal.vue";
import {Blog, blogInit} from "@/types/blog";

@Component({
  components: {
    BlogDeleteModal,
  },
})
export default class AdminBlogView extends Vue {
  blogId = '';
  loading = false;
  blogData: Blog = blogInit();
  thumbnail = '';
  tag = '';

  created() {
    this.blogId = this.$route.params.blogId;
    this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/admin/board/blog/getBlogContent/${this.blogId}`);
      const { result, returnData } = data;
      if (result) {
        this.blogData = returnData.article;
        if(returnData.article.THUMBNAILFILE.length){
          this.thumbnail=returnData.article.THUMBNAILFILE[0].name
        }
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  remove(): void {
    this.$bvModal.show('board-remove-modal');
  }
  goEdit(){
    this.$router.push(`/admin/blog-edit/${this.blogId}`);
  }
}
</script>

<style scoped>
#board-help td {
  text-align: left;
  padding: 15px;
}

.border-line {
  border: 1px solid var(--line);
}
.line-height{
  line-height: 50px;
}
.question-title span{
  height: 20px;
}
</style>
