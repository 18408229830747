<template>
  <section>
    <b-card
      class="m-4"
      header="uni-survey k8s"
    >
      <div class="d-flex justify-content-end align-items-end flex-column">
        <router-link
          variant="transparent"
          class="mb-2"
          :to="{ path: `/admin/k8s` }"
        >
          <b-card-img-lazy
              src="https://809064584-files.gitbook.io/~/files/v0/b/gitbook-legacy-files/o/spaces%2F-M_a7PGjEhpIoPdQwI7L%2Favatar-1621153022736.png?generation=1621153023256362&alt=media"
              alt="uni-survey k8s"
              class="img-fluid rotate"
              top
          />
        </router-link>
        <small class="text-primary" style="font-size:.75rem;">k8s 대시보드에서 더 자세히 설정이 가능합니다</small>
      </div>
      <b-card-body>
        <div class="d-flex justify-content-around">
          <router-link to="/admin/k8s/apps" :class="path.startsWith('/admin/k8s/apps') ? 'font-weight-bold' : ''">Apps</router-link>
          <router-link to="/admin/k8s/nodes" :class="path.startsWith('/admin/k8s/nodes') ? 'font-weight-bold' : ''">Nodes</router-link>
          <router-link to="/admin/k8s/networks" :class="path.startsWith('/admin/k8s/networks') ? 'font-weight-bold' : ''">Networks</router-link>
        </div>
        <hr/>
        <router-view />
      </b-card-body>
    </b-card>
    <div class="position-fixed" style="right: 5rem; bottom: 3rem;">
      <b-button-group>
        <b-button
            :href="k8sDashboardUrl" target="_blank"
            variant="primary"
        >
          <b-icon-bar-chart />
        </b-button>
        <b-button
          :href="kubeOpsViewUrl" target="_blank"
          variant="primary"
        >
          <b-icon-compass />
        </b-button>
      </b-button-group>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Token } from '@/interface/admin/k8s/token';
@Component({})
export default class K8sIndex extends Vue {

  private get k8sDashboardUrl() {
    return `https://kubernetes--kubernetes-d-a629b-15232023-b0653684310c.kr.lb.naverncp.com/#/workloads?namespace=simple-survey`;
  }

  private get kubeOpsViewUrl() {
    return `http://simple-surv-kube-ops-vie-6b0bd-15232019-628a039b874a.kr.lb.naverncp.com`;
  }

  get path() {
    return this.$route.path;
  }

  mounted() {
    this.generateDashboardToken();
  }

  private async generateDashboardToken() {
    if (this.path !== '/admin/k8s') return;
    const url = `/admin/k8s/dashboard/token`;
    const { data: { body: { status: { token } } } } = await this.axios.get<Token>(url);
    await window.navigator.clipboard.writeText(token);
    this.$bvToast.toast('Dashboard Token이 클립보드에 복사되었습니다.', {
      title: 'Dashboard Token',
      variant: 'success',
      solid: true,
    });
  }
}

</script>

<style scoped>
  section {
    max-width: 1080px;
    margin: 0 auto;
  }
  .rotate{
    animation: rotate 10s linear infinite;
    width: 2.5rem;
    height: 2.5rem;
  }
  @keyframes rotate{
    to{ transform: rotate(360deg); }
  }
</style>
