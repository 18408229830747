<template>
  <b-modal id="new-template-registration-modal" hide-footer hide-header centered>
    설문 제목 :
    <uni-font size="18" weight="bold" color="textTitle" class="make-template-modal-title">{{ templateTitle }}</uni-font>

    <div class='my-2'>
      <div>
        카테고리 항목을 선택해 주세요.
        <div class='d-flex flex-column'>
          <div>
            <label for="category">대분류</label>
            <select v-model="selectedCategory" class="form-control" id="category">
              <option v-for="category in categories" :key="category.value" :value="category.value">{{ category.label }}</option>
            </select>
          </div>

          <div v-if="selectedCategory" class='my-2'>
            <label for="subcategory">주제</label>
            <select v-model="selectedSubcategory" class="form-control" id="subcategory">
              <option v-for="subcategory in filteredSubcategories" :key="subcategory.value" :value="subcategory.value">{{ subcategory.label }}</option>
            </select>
          </div>
        </div>
      </div>

      <b-form-group  class='my-2' label-for="copy-title" description="문항의 개수를 입력해 주세요." id="new-template-total-question">
        <b-form-input
          v-model="newTemplateTotalQuestion"
          class="border-4 input-focus-bg font-weight-bold font new-template-input"
          ref="templateTotalQuestion"
          placeholder="0"
          type="number"
        />
      </b-form-group>

      <b-form-group  class='my-2' label-for="copy-title" description="설문 예상 시간을 입력해 주세요." id="new-template-total-time">
        <b-form-input
          v-model="newTemplateTotalTime"
          class="input-focus-bg font-weight-bold font new-template-input"
          ref="templateTotalTime"
          placeholder="0"
          type="number"
        />
      </b-form-group>
    </div>
    <div class="d-flex custom-mt-16 w-100">
      <uni-button variant='secondary-outline' class="custom-mr-10" :click="() => closeTemplateModal()">닫기</uni-button>
      <uni-button variant='primary' :click="() => save()">등록</uni-button>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import { IProjectConfig, IQuestionValue } from '@/interface/admin/question';

@Component
export default class MakeTemplateModal extends Vue {
  @Prop() templateTitle?: string;
  @Prop({default: null}) templateConfig?: IProjectConfig | null;
  @Prop() templateInfo?: Array<IQuestionValue>;

  @Ref() readonly templateCategory!: HTMLElement;
  @Ref() readonly templateTotalTime!: HTMLElement;
  @Ref() readonly templateTotalQuestion!: HTMLElement;

  newTemplateCategory = '';
  newTemplateTotalQuestion = '';
  newTemplateTotalTime = '';

  selectedCategory = '소비자';
  selectedSubcategory = 'NPS';

  categories = [
    {
      label: '소비자',
      value: '소비자',
      default: 'NPS',
      children: [
        {label: 'NPS', value: 'NPS'},
        {label: 'VOC', value: 'VOC'},
      ]
    },
    {
      label: '브랜드',
      value: '브랜드',
      default: '인지도',
      children: [
        {label: '인지도', value: '인지도'},
        {label: '이미지', value: '이미지'},
        {label: '구매 경험', value: '구매 경험'},
        {label: '구매 패턴', value: '구매 패턴'},
        {label: '만족도', value: '만족도'},
        {label: '로열티', value: '로열티'},
        {label: '선호도', value: '선호도'},

      ]
    },
    {
      label: '제품',
      value: '제품',
      default: '제품 진단',
      children: [
        {label: '제품 진단', value: '제품 진단'},
        {label: '컨셉 평가', value: '컨셉 평가'},
        {label: '가격 평가', value: '가격 평가'},
        {label: '만족도', value: '만족도'},
        {label: '로열티', value: '로열티'},
        {label: '선호도', value: '선호도'},
      ]
    },
    {
      label: '서비스',
      value: '서비스',
      default: '오프라인(매장)',
      children: [
        {label: '오프라인(매장)', value: '오프라인(매장)'},
        {label: '웹사이트', value: '웹사이트'},
        {label: 'APP/모바일', value: 'APP/모바일'},
        {label: 'SNS', value: 'SNS'},
        {label: '만족도', value: '만족도'},
        {label: '선호도', value: '선호도'},
      ]
    },
    {
      label: '광고',
      value: '광고',
      default: '브랜드 광고',
      children: [
        {label: '브랜드 광고', value: '브랜드 광고'},
        {label: '광고 모델', value: '광고 모델'},
        {label: '만족도', value: '만족도'},
        {label: '선호도', value: '선호도'},
      ]
    }
  ]

  get filteredSubcategories() {
    const category = this.categories.find(cat => cat.value === this.selectedCategory);
    return category ? category.children : [];
  }

  setTemplateInfo() {
    return {
      TITLE: this.templateTitle,
      DATA: this.templateInfo,
      CONFIG: this.templateConfig,
      CATEGORY: this.selectedCategory,
      SUB_CATEGORY: this.selectedSubcategory,
      ANSCOUNT: `${this.newTemplateTotalQuestion}문항`,
    };
  }

  validation(): boolean {
    if (!this.newTemplateCategory.length) {
      this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_EMPTY_CATEGORY, ToastVariant.DANGER, this.$bvToast);
      this.$nextTick(() => this.templateCategory.focus());
      return false;
    } else if (!this.newTemplateTotalQuestion.length) {
      this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_EMPTY_SURVEY_COUNT, ToastVariant.DANGER, this.$bvToast);
      this.$nextTick(() => this.templateTotalQuestion.focus());
      return false;
    } else {
      return true;
    }
  }

  @Watch('selectedCategory')
  selectedSubCategory(newCategory) {
    const category = this.categories.find(cat => cat.value === newCategory);
    if (category) {
      this.selectedSubcategory = category.default;
    }
  }

  closeTemplateModal() {
    this.resetValue();
    this.$bvModal.hide('new-template-registration-modal');
  }

  async save() {
    try {
      const validate = this.validation();
      const sendData = this.setTemplateInfo();
      if (validate) {
        const { data } = await this.axios.post('/project/new/template', sendData);
        const { result } = data;

        if (result) {
          this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_SUCCESS, ToastVariant.SUCCESS, this.$bvToast);
        } else {
          this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_FAILED, ToastVariant.DANGER, this.$bvToast);
        }
        this.resetValue();
        this.$bvModal.hide('new-template-registration-modal');
      }
    } catch (e) {
      console.log(e);
    }
  }

  resetValue() {
    this.newTemplateCategory = '';
    this.newTemplateTotalQuestion = '';
    this.newTemplateTotalTime = '';
  }
}
</script>

<style scoped></style>
