<template>
  <div class="p-4">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
@Component({})
export default class K8sAppsIndex extends Vue {

}

</script>

<style scoped>

</style>
