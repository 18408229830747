<template>
  <section class="mx-auto">
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="py-4" />
    <b-overlay :show="loading">
      <div>
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column align-content-start justify-content-start align-items-start">
            <div v-if="metaData">
              {{ metaData.name }}
            </div>
            <h5>Pods</h5>
          </div>
          <div>
            <b-button-group>
              <b-button variant="primary" size="sm" @click="load">
                <b-icon-arrow-clockwise />
              </b-button>
            </b-button-group>
          </div>
        </div>
        <div
            v-for="{ metadata, status: { hostIP, phase, podIPs } } in items" :key="metadata.uid"
            class="border-bottom d-flex justify-content-between p-2"
        >
          <pod-component
            :namespace="namespace"
            :deployment="deployment"
            :metadata="metadata"
            :hostIP="hostIP"
            :phase="phase"
            :podIPs="podIPs"
          />
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Pod, BodyItem } from '@/interface/admin/k8s/pod';
import { DeploymentOne, BodyMetadata } from '@/interface/admin/k8s/deployment.one';
import PodComponent from './compoment/pod.vue';
@Component({
  components: {
    PodComponent,
  },
})
export default class K8sPod extends Vue {
  private loading = false;
  private items: BodyItem[] = [];
  private metaData: BodyMetadata | null = null;

  get namespace () {
    return this.$route.params.namespace;
  }

  get deployment () {
    return this.$route.params.deployment;
  }

  get pod () {
    return this.$route.params.pod;
  }

  private async created() {
    this.load();
  }

  private async deploymentInfo() {
    const url = `/admin/k8s/deployment/${ this.namespace }/${ this.deployment }`;
    const { data: { body: { metadata } } } =await this.axios.get<DeploymentOne>(url);
    return metadata;
  }

  private async podsList() {
    const url = `/admin/k8s/pod/${this.namespace}/${ this.deployment }`;
    const { data: { body: { items } } } = await this.axios.get<Pod>(url);
    return items;
  }

  private async load() {
    try {
      this.loading = true;
      this.metaData = await this.deploymentInfo();
      this.items = [];
      this.items = await this.podsList();
    } finally {
      this.loading = false;
    }
  }

}

</script>

<style scoped>
section {
  max-width: 960px;
}
</style>
