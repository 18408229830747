<template>
  <section class="mx-auto">
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="py-4" />
    <b-overlay :show="loading">
      <div>
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column align-content-start justify-content-start align-items-start">
            <div v-if="metaData">
              {{ metaData.name }}
            </div>
            <h5>Deployments</h5>
          </div>
          <div>
            <b-button-group>
              <b-button variant="primary" size="sm" @click="load">
                <b-icon-arrow-clockwise />
              </b-button>
            </b-button-group>
          </div>
        </div>
        <div
            v-for="{ metadata, status: { replicas: replicasCount } } in items" :key="metadata.uid"
            class="border-bottom d-flex justify-content-between p-2"
        >
          <router-link :to="{  path: `/admin/k8s/apps/${ namespace }/${ metadata.name }`, }">
            <small :class="deployment === metadata.name ? 'font-weight-bold' : ''">{{ metadata.name }}</small>
          </router-link>
          <div class="d-flex">
            <b-button-group
              v-if="deployment === metadata.name"
            >
              <b-button variant="warning" size="sm" v-b-modal="metadata.uid">
                <b-icon-zoom-in />
              </b-button>
              <b-modal
                  :title="metadata.name"
                  :id="metadata.uid"
                  centered
                  size="xl"
                  hide-footer
              >
                <vue-json-pretty
                    :data="metadata"
                    :height="300"
                    :showLength="true"
                    :showLineNumber="true"
                    :showIcon="true"
                    :virtual="true"
                />
              </b-modal>
              <b-button variant="primary" size="sm" @click="scaleDeployment(replicasCount + 1)">
                <b-icon-cloud-arrow-up />
              </b-button>
              <b-button variant="danger" size="sm" @click="scaleDeployment(replicasCount - 1)">
                <b-icon-cloud-arrow-down />
              </b-button>
            </b-button-group>
            <div class="px-1" />
            <div>
              <b-badge variant="success">
                replicas: {{ replicasCount }}
              </b-badge>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Item, Deployment } from '@/interface/admin/k8s/deployment';
import { NamespaceOne, Metadata } from '@/interface/admin/k8s/namespace.one';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sDeployment extends Vue {
  private loading = false;
  private items: Item[] = [];
  private metaData: Metadata | null = null;

  get namespace () {
    return this.$route.params.namespace;
  }

  get deployment () {
    return this.$route.params.deployment;
  }

  private async created() {
    this.load();
  }

  private async namespaceInfo() {
    const url = `/admin/k8s/namespace/${ this.namespace }`;
    const { data: { body: { metadata } } } =await this.axios.get<NamespaceOne>(url);
    return metadata;
  }

  private async deploymentList() {
    const url = `/admin/k8s/deployment/${this.namespace}`;
    const { data: { body: { items } } } = await this.axios.get<Deployment>(url);
    return items;
  }

  private async scaleDeployment(replicas: number) {
    try {
      this.loading = true;
      console.log(replicas, typeof replicas)
      const url = `/admin/k8s/deployment/${this.namespace}/${this.deployment}`;
      const { data } = await this.axios.patch(url, { replicas });
      console.log(data);
      this.items = await this.deploymentList();
    } finally {
      this.loading = false;
    }
  }

  private async load() {
    try {
      this.loading = true;
      this.metaData = await this.namespaceInfo();
      this.items = await this.deploymentList();

    } finally {
      this.loading = false;
    }
  }

}

</script>

<style scoped>
section {
  max-width: 960px;
}
</style>
