<template>
  <section class="mx-auto">
    <router-view />
    <b-overlay :show="loading">
      <div>
        <div v-if="metaData">
          <h5>{{ metaData.name }}</h5>
          <div>
            <small>{{ new Intl.DateTimeFormat('ko-KR', { dateStyle: 'full', timeStyle: 'long' }).format(new Date((metaData.creationTimestamp))) }}</small>
          </div>
          <div class="d-flex justify-end align-items-end justify-content-end">
            <b-button
                :variant="toggle ? 'outline-primary' : 'primary'" @click="toggle = !toggle" size="sm">
              <b-icon icon="info-circle" />
            </b-button>
          </div>
          <b-collapse :visible="toggle">
            <vue-json-pretty
                :data="metaData"
            />
          </b-collapse>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Node, Item } from '@/interface/admin/k8s/node';
import { NodeOne, Metadata } from '@/interface/admin/k8s/node.one';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sNode extends Vue {
  private loading = false;
  private toggle = false;
  private metaData: Metadata | null = null;

  get node() {
    return this.$route.params.node;
  }

  created() {
    this.load();
  }

  async nodeInfo() {
    const url = `/admin/k8s/nodes/${this.node}`;
    const { data: { body: { metadata } } } = await this.axios.get<NodeOne>(url);
    return metadata;
  }

  private async load() {
    try {
      this.loading = true;
      this.metaData = await this.nodeInfo();
    } finally {
      this.loading = false;
    }
  }
}

</script>

<style scoped>

</style>
