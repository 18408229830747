<template>
  <div>
    <main class="sub-layout-container custom-pb-200">
      <article class="sub-layout-top-container">
        <section class="sub-items-title-wrapper">
          <h1 class="sub-items-title">{{ this.tabIndex === 0? '공지사항': '도움말' }}</h1>
        </section>

        <section class="sub-layout-body-container">
          <div class="w-100">
            <table class="table-container w-100">
              <tr>
                <th>
                  <uni-font color="textSub" size="14">
                    제목
                  </uni-font>
                </th>
                <td class="question-title">
                  <uni-font class="w-100 text-left" color="textTitle" size="14">{{ help.TITLE }}</uni-font>
                </td>
              </tr>
              <tr>
                <th>
                  <uni-font color="textSub" size="14">
                    카테고리
                  </uni-font>
                </th>
                <td class="text-left">
                  <uni-font class="w-100 text-left" color="textTitle" size="14">{{ help.CATEGORY }}</uni-font>
                </td>
              </tr>
              <tr v-if="help.FILES.length > 0">
                <th>
                  <uni-font color="textSub" size="14">
                    첨부파일
                  </uni-font>
                </th>
                <td class="text-left">
                  <uni-font>
                    <a :href="help.FILES[0].Location">{{help.FILES[0].name}}</a>
                  </uni-font>
                </td>
              </tr>
              <tr>
                <th>
                  <uni-font color="textSub" size="14">
                    내용
                  </uni-font>
                </th>
                <td class="question-content">
                  <div v-html="help.CONTENTS"></div>
                </td>
              </tr>
              <tr>
                <th>
                  <uni-font color="textSub" size="14">
                    태그
                  </uni-font>
                </th>
                <td class="text-left">
                  <div>
                    <b-badge
                      class="mr-1"
                      v-for="(tag, tagIndex) in help.TAG"
                      :key="`tag-${tagIndex}`"
                      variant="primary"
                    >
                      # {{ tag }}
                    </b-badge>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="w-100 mt-3 d-flex justify-content-end">
            <uni-button
              v-if="help.REGIST_ID === $store.getters.userId"
              variant="red"
              :click="remove"
              class="custom-mr-16"
            >
              <uni-font color="white" size="14">삭제</uni-font>
            </uni-button>
            <uni-button
              :click="() => $router.push({name: 'qna-list', query: {tabIndex: this.tabIndex}})"
              variant="secondary-outline"
              class="custom-mr-16"
            >
              <uni-font color="placeholder" size="14">목록</uni-font>
            </uni-button>

            <uni-button
              :click="() => $router.push({name: 'qna-modify', params:{helpId: this.helpId}, query: {tabIndex: this.tabIndex}})"
              v-if="help.REGIST_ID === $store.getters.userId"
            >
              <uni-font color="white" size="14">수정</uni-font>
            </uni-button>
          </div>
        </section>
      </article>
    </main>
    <help-delete-modal :list-id="helpId" :tabIndex="tabIndex"/>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {helpInit, IHelp} from '@/types/board';
import HelpDeleteModal from '@/components/modal/HelpDeleteModal.vue';

@Component({
  components: {
    HelpDeleteModal,
  },
})
export default class AdminHelpRead extends Vue {
  helpId = '';
  tabIndex = '';
  loading = false;
  help: IHelp = helpInit();
  tag = '';

  created() {
    this.helpId = this.$route.params.helpId;
    this.tabIndex = this.$route.query.tabIndex[0] || '';
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/admin-select-help/${this.helpId}`);
      const { result, help } = data;
      if (result) {
        this.help = help;
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  remove(): void {
    this.$bvModal.show('board-remove-modal');
  }
}
</script>

<style scoped>
#board-help td {
  text-align: left;
  padding: 15px;
}

.border-line {
  border: 1px solid var(--line);
}
.line-height{
  line-height: 50px;
}
.question-title span{
  height: 20px;
}
</style>
