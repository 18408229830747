<template>
  <b-form class="justify-content-start d-flex align-items-center">
    <b-form-file
      ref="formFile"
      class="col-6 nanumgothic-bold-granite-gray-14px input-focus-bg"
      accept=".jpg, .png, ,jpeg"
      :placeholder=getPlaceholer()
      drop-placeholder="Drop file here..."
      v-on:change="fileUpload"
      browse-text="UPLOAD"
    />
  </b-form>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {},
})
export default class ImageUpload extends Vue {
  file: Blob[] = [];
  dataUrl: string;
  @Prop() thumbnailImageName: string;

  thumbnailName = '';

  $refs: Vue['$refs'] & {
    formFile: HTMLFormElement;
  };

  async upload() {
    const isNullFile = this.file.length === 0;
    if (isNullFile) return this.$common.makeToast(ToastMessage.IMAGE_UPLOAD, ToastVariant.DANGER, this.$bvToast);
    this.uploadResult(this.file[0]);
  }

  @Emit('uploadResult')
  uploadResult(file) {
    this.file = [];
    this.$refs.formFile.reset();
    this.thumbnailName = file.name;
    console.log('changeTHumbnamil', this.thumbnailName);
    return {
      file: file,
      dataUrl: this.dataUrl,
    };
  }

  getPlaceholer(){
    if(this.thumbnailName !== "" || this.thumbnailImageName !=="") {
      if(this.thumbnailImageName!='') this.thumbnailName = this.thumbnailImageName;
      return this.thumbnailName;
    }else{
      return '업로드 할 썸네일을 선택해주세요.'
    }
  }

  async getDataUrl(files): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => resolve(reader.result + '');
    });
  }

  async fileUpload(event: any) {
    const files = event.target.files;
    const typeList = ['jpg', 'jpeg', 'png'];
    if (files.length === 0) return this.$common.makeToast(ToastMessage.IMAGE_ONE, ToastVariant.DANGER, this.$bvToast);
    const { type } = files[0];
    const [image, imageType] = type.split('/');

    if (image !== 'image' || !typeList.includes(imageType)) {
      this.$common.makeToast(ToastMessage.IMAGE_TYPE, ToastVariant.DANGER, this.$bvToast);
      this.file = [];
      this.$refs.formFile.reset();
      return;
    }

    this.file = files;
    this.dataUrl = await this.getDataUrl(files);
    await this.upload();
  }
}
</script>

<style scoped></style>
