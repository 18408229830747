<template>
  <b-modal visible @hidden="close(false)" id="user-info-update-modal" ref="modal" centered hide-header hide-footer>
    <article>
      <section class="pb-2">
        <h1 class="text-10">변경할 닉네임을 입력해 주세요.</h1>
      </section>
      <section class="custom-mt-10">
        <input
          class="update-input"
          type="text"
          :placeholder="nickname"
          @keypress.enter="changeNickname"
          v-model="nickname"
          ref="refNickname"
        />
      </section>
      <section id="change-user-information-button-container" class="custom-mt-16">
        <button class="close-button custom-mr-10" @click="$bvModal.hide('user-info-update-modal')">취소</button>
        <button class="confirm-button" @click="changeNickname">확인</button>
      </section>
    </article>
  </b-modal>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({})
export default class ChangeNickname extends Vue {
  @Prop() userNickname!: string;
  @Prop() userId!: string;
  @Ref() readonly refNickname!: HTMLElement;
  private nickname = '';

  @Emit('close')
  close(bool = false) {
    return bool;
  }

  mounted() {
    // 바로 줬을 경우 focus가 가질 않아 setTimeout 사용
    setTimeout(() => {
      this.$nextTick(() => this.refNickname.focus());
    }, 300);
  }

  private async changeNickname() {
    const sendData = {
      userId: this.userId,
      newNickname: this.nickname,
    };
    // 동일한 이름일 경우 변경 불가능
    if (this.userNickname === this.nickname) {
      Vue.$toast.error('동일한 이름으로 변경하실 수 없습니다.');
      this.nickname = '';
      return;
    }

    const { data } = await this.axios.patch('/user/change/nickname', sendData);
    const { result } = data;
    if (result) {
      setTimeout(() => {
        Vue.$toast.success('변경되었습니다.');
      }, 100);
      this.close(true);
    }
  }
}
</script>

<style scoped>
#change-user-information-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.text-10 {
  color: var(--chicago);
  font-family: var(--font-family-nanum_gothic);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: -0.48px;
  margin-top: 20px;
  min-height: 18px;
}
</style>
