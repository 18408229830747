<template>
  <article
    v-if="!loading"
  >
    <div class="quota-layout">
      <div class="text-container custom-mt-5">
        <section class="d-flex align-items-center w-100">
          <h1 class="text-2 nanumgothic-bold-fuscous-gray-20px">참여 현황</h1>
          <div class="completed-count-wrapper">
            <span class="completed-count">{{ maxCompleteFormat }} 명 목표 / {{ completeFormat }}명 완료</span>
          </div>
        </section>
        <section
          class="text-left w-100"
          v-if="maxCompleteFormat === completeFormat && $route.path.split('/')[2] === 'quota'"
        >
          <h2 class="custom-pl-25 nanumgothic-bold-fuscous-gray-16px">
            모든 유입이 완료되었습니다. 설문 결과를 확인해 보세요.
          </h2>
        </section>
        <section
          class="w-100 d-flex justify-content-end"
          :class="$route.path.split('/')[2] === 'quota' ? '' : ' d-flex justify-content-end'"
        >
          <div class="d-flex align-items-center justify-content-between" v-if="$route.path.split('/')[2] === 'quota'">
            <button type="button" class="secondary-button custom-mr-10" @click="redirectPage('make')">
              <font-awesome-icon class="custom-mr-10" :icon="['fas', 'arrow-left']" />
              설문보기
            </button>
            <button type="button" class="secondary-button" @click="redirectPage('result')">
              결과보기
              <font-awesome-icon class="custom-ml-10" :icon="['fas', 'arrow-right']" />
            </button>
          </div>

          <button
            v-if="$route.path.split('/')[2] === 'result'"
            type="button"
            class="secondary-button m-0"
            @click="redirectPage('quota')"
          >
            <font-awesome-icon class="custom-mr-10" :icon="['fas', 'arrow-left']" />
            현황보기
          </button>
        </section>
      </div>
    </div>

    <section class="progress-bar-container">
      <div class="quota-layout progress-flex w-100">
        <b-progress :max="+maxCompleteFormat" v-if="surveyParticipation.ALL.COUNT > 0" class="w-100">
          <b-progress-bar :value="+surveyParticipation.COMPLETE.COUNT" variant="primary">
            {{ completePercent }}
            ({{ completeFormat }})
          </b-progress-bar>
        </b-progress>
      </div>
    </section>

    <section
      class="quota-layout custom-mt-10"
      :style="componentType === 'result' ? 'margin-top: 0px;' : 'margin-top: 0px;'"
    >
      <table class="quota-participation-container">
        <thead>
        <tr class="quota-title">
          <th class="w-30 nanumgothic-bold-fuscous-gray-16px">참여</th>
          <th class="w-30 nanumgothic-bold-fuscous-gray-16px">완료</th>
          <th class="w-30 nanumgothic-bold-fuscous-gray-16px">진행중</th>
        </tr>
        </thead>
        <tbody>
        <tr class="quota-value">
          <td class="w-30 nanumgothic-bold-granite-gray-16px">{{ allFormat }}</td>
          <td class="w-30 nanumgothic-bold-granite-gray-16px">{{ completeFormat }}</td>
          <td class="w-30 nanumgothic-bold-granite-gray-16px">{{ dropFormat }}</td>
        </tr>
        </tbody>
      </table>
    </section>
  </article>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class QuotaParticipation extends Vue {
  @Prop() surveyParticipation: {
    ALL: { NAME: string; COUNT: number };
    BAD: { NAME: string; COUNT: number };
    COMPLETE: { NAME: string; COUNT: number };
    DROP: { NAME: string; COUNT: number };
    OVER: { NAME: string; COUNT: number };
    OUT: { NAME: string; COUNT: number };
  };
  snum = '';
  @Prop({ default: 0 }) limitCount: number;
  @Prop({ default: '' }) componentType: string;
  @Prop() projectId: number;
  @Prop() loading: boolean;

  percent(target: number, total: number): string {
    return ((target / total) * 100).toFixed(1) + '%';
  }

  redirectPage(type: string) {
    let url = '';
    switch (type) {
      case 'make':
        url = `/project/make/${this.projectId}`;
        break;
      case 'result':
        url = `/project/result/${this.projectId}`;
        break;
      case 'quota':
        url = `/project/quota/${this.projectId}`;
        break;
    }
    this.$router.push(url);
  }

  get maxCompleteFormat(): string {
    return this.$common.numberFormat(this.limitCount);
  }

  get completePercent(): string {
    const target = this.surveyParticipation.COMPLETE.COUNT;
    return this.percent(target, this.limitCount);
  }

  get outPercent(): string {
    const target = this.surveyParticipation.OUT.COUNT;
    const total = this.surveyParticipation.ALL.COUNT;
    return this.percent(target, total);
  }

  get overPercent(): string {
    const target = this.surveyParticipation.OVER.COUNT;
    const total = this.surveyParticipation.ALL.COUNT;
    return this.percent(target, total);
  }

  get dropPercent(): string {
    const target = this.surveyParticipation.DROP.COUNT;
    const total = this.surveyParticipation.ALL.COUNT;
    return this.percent(target, total);
  }

  get allFormat(): string {
    const target = this.surveyParticipation.ALL.COUNT;
    return this.$common.numberFormat(target);
  }

  get completeFormat(): string {
    const target = this.surveyParticipation.COMPLETE.COUNT;
    return this.$common.numberFormat(target);
  }

  get outFormat(): string {
    const target = this.surveyParticipation.OUT.COUNT;
    return this.$common.numberFormat(target);
  }

  get overFormat(): string {
    const target = this.surveyParticipation.OVER.COUNT;
    return this.$common.numberFormat(target);
  }

  get dropFormat(): string {
    const target = this.surveyParticipation.DROP.COUNT;
    return this.$common.numberFormat(target);
  }
}
</script>

<style scoped>
.quota-layout {
  align-items: flex-start;
  display: flex;
  margin: auto;
}

.text-container {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 23px;
  min-width: 247px;
}
.text-2 {
  letter-spacing: -0.6px;
  min-height: 23px;
  min-width: 79px;
}

.completed-count {
  display: inline-block;
  color: var(--white);
  font-family: var(--font-family-nanum_gothic);
  font-size: var(--font-size-xs);
  font-weight: 700;
  letter-spacing: -0.77px;
  min-height: 16px;
  min-width: 138px;
}

.completed-count-wrapper {
  width: fit-content;
  height: fit-content;
  padding: 3px 4px 3px 4px;
  border-radius: 17px;
  background-color: #35b6e7;
  margin-left: 30px;
}

.quota-title {
  background-color: #f7f7f7;
  border: 0.5px solid;
  border-color: var(--line);
  height: 40px;
  width: 712px;
}
.quota-value {
  background-color: var(--textwhite);
  border: 0.5px solid;
  border-color: var(--line);
  height: 58px;
  width: 714px;
}

.progress-bar-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.progress-flex {
  width: 712px;
  margin: 20px 0 0 0;
}

.quota-participation-container {
  align-self: center;
  height: 97px;
  min-width: 712px;
}
</style>
