<template>
  <div>
    <main class="sub-layout-container">
      <article class="sub-layout-top-container mt-5">
        <b-overlay spinner-variant="primary" :show="loading" rounded="sm" class="sub-layout-body-container mt-5">
          <article class="w-100">
            <b-tabs pills v-model="tabIndex">
              <b-tab title="공지사항">
                <NoticeTable
                  v-if="tabIndex ===0"
                  :tabIndex="tabIndex"
                  :key="tabIndex"
                />
              </b-tab>
              <b-tab title="도움말">
                <NoticeTable
                  v-if="tabIndex ===1"
                  :tabIndex="tabIndex"
                  :key="tabIndex"
                />
              </b-tab>
            </b-tabs>
          </article>
        </b-overlay>
      </article>
    </main>

    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {IHelp, HELP_CATEGORY} from '@/types/board';
import NoticeTable from "@/components/help/NoticeTable.vue";

@Component({
  components: {
    NoticeTable,
  }
})
export default class AdminHelpList extends Vue {
  loading = true;
  helpList: IHelp[] = [];
  page = 1;
  totalRow = 0;
  limitPage = 10;
  search = '';
  searchButtonHover = true;
  category= this.$route.params.category;
  tabIndex = Number(this.$route.query.tabIndex) || 0;

  async created() {
    await this.load();
  }

  /*activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }
*/
  async load() {
    try {
      const sendData = {
        limit: this.limitPage,
        page: this.page,
        search: this.search,
      };
      const { data } = await this.axios.get(`/board/select-help`, { params: sendData });
      const { result, count, list } = data;
      if (result == true) {
        this.helpList = list;
        this.totalRow = count;
      }
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  }
/*
  get helpListLength(): number {
    return this.helpList.length;
  }*/

/*  categoryVariant(category: HELP_CATEGORY): string {
    if (category === HELP_CATEGORY.NOTICE) return 'warning';
    else if (category === HELP_CATEGORY.SCRIPT) return 'success';
    else return 'primary';
  }*/

/*  helpView(index: number) {
    const list: IHelp = this.helpList[index];
    const { _id } = list;
    this.$router.push(`/admin/qna-read/${_id}`);
  }*/
}
</script>

<style scoped>
.table-wrap {
  min-height: 500px;
}
.button-size {
  width: 10%;
  height: 45px;
  position: relative;
  left: 46.5%;
  bottom: 60px;
}

.help-board-top {
  width: 100%;
  text-align: right;
  padding-bottom: 15px;
}

.help-board-footer {
  height: 50px;
  text-align: center;
}

.payment-table-text {
  letter-spacing: -0.48px;
  font-weight: 400;
  text-align: center;
}

.help-items {
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  max-height: 40px;
  height: 100%;
  letter-spacing: -0.48px;
  font-weight: 400;
}
</style>
