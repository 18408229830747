<template>
  <div>
    <main class="sub-layout-container custom-pb-100">
      <article class="sub-layout-top-container">
        <section class="sub-items-title-wrapper">
          <span class="sub-items-title">블로그</span>
        </section>

        <b-overlay wrap-tag="article" :show="showing" rounded="sm" class="sub-layout-body-container">
          <article class="w-100">
            <b-row class="mt-3">
              <section class="help-board-top d-flex align-items-center">
                <div class="ml-auto d-flex align-items-center">
                   <select
                    class="my-survey-select-box"
                    size="sm"
                    v-model="searchType"
                    @change="search = ''"
                  >
                    <option
                      v-for="(option, index) of searchOptions"
                      class="nanumgothic-bold-granite-gray-13px testCss"
                      :value="option.value"
                      :key="index"
                    >
                      {{ option.text }}
                    </option>
                  </select>

                  <div v-if="searchType === 'category'">
                    <b-form-select
                      class="status-selector"
                      v-model="search"
                      :options="categoryOptions"
                      @change="searchSubmit"
                      size="sm"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled>
                          카테고리를 선택해 주세요
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                  <div v-else>
                    <input
                      class="search-bar-wrapper"
                      type="text"
                      @keyup.enter="searchSubmit"
                      @focusin="searchButtonHover = false"
                      @focusout="searchButtonHover = true"
                      v-model="search"
                      placeholder="검색"
                    />
                    <button type="button" class="search-button search-button-icon" @click="searchSubmit">
                      <img
                        src="@/assets/images/icons/main/search-button.svg"
                        @mouseover="activeSearchButton"
                        alt="검색 버튼"
                        v-if="searchButtonHover"
                      />
                      <img
                        src="@/assets/images/icons/main/search-button-active.svg"
                        @mouseleave="activeSearchButton"
                        alt="검색 버튼"
                        v-else
                      />
                    </button>
                  </div>
                </div>
              </section>

              <b-col cols="12">
                <BlogTable
                  :blogList="blogList"
                  :loading="loading"
                  :key="`blog-${loading}`"
                  @view="blogView"
                ></BlogTable>
                <b-col cols="12" class="help-board-footer">
                  <b-pagination-nav
                    use-router
                    align="center"
                    v-model="page"
                    :number-of-pages="numberOfPages"
                    :limit="7"
                    :link-gen="linkGen"
                  />
                  <button
                      class="confirm-button button-size"
                      id="btn-add"
                      @click="$router.replace({name: 'blog-write'})"
                  >
                    블로그 작성
                  </button>
                </b-col>
              </b-col>
            </b-row>
          </article>
        </b-overlay>
      </article>
    </main>
    <Footer />
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import BlogTable from "@/components/blog/BlogTable.vue";

@Component({
  components: {
    BlogTable
  }
})
export default class AdminBlogList extends Vue{
  page = 1;
  search = '';
  searchType = 'title';
  totalRow = 0;
  perPage = 10;
  loading = false;
  blogList = [];
  showing = false;

  searchButtonHover = true;

  searchOptions=[
    { value: 'title', text: '제목' },
    { value: 'content', text: '내용' },
    { value: 'category', text: '카테고리' },
  ]
  categoryOptions = [
    { value: '기획조사', text: '기획조사' },
    { value: '리서치지식', text: '리서치지식' },
  ];

  created(){
    this.load();
  }

  async load(){
    this.loading = true;
    try{
      const params = {
        page: this.page,
        search: this.search,
        searchType: this.searchType,
      };
      const { data } = await this.axios({
        url: '/admin/board/blog/getBlogList',
        method: 'GET',
        params,
      });
      const { list, count, result, numberOfPages } = data;
      if(result){
        this.blogList = list;
        this.totalRow = count;
      }
    }catch (e){
      console.error(e);
    }
    this.loading = false;
  }
  get numberOfPages() {
    return Math.ceil(this.totalRow / this.perPage) || 1;
  }
  linkGen(page: number) {
    return this.makeQuery({ page });
  }
  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp).map(entry => entry.join('=')).join('&');

    return `?${query}`;
  }

  blogView(blogId){
    this.$router.push(`/admin/blog-view/${blogId}`);
  }

  changeFilter(filterValue: { [key: string]: string | number }) {
    const query = this.makeQuery({
      page: 1,
      ...filterValue
    })
    this.$router.push(query);
  }

  searchSubmit() {
    this.changeFilter({
      search: this.search,
      searchType: this.searchType,
    })
  }
  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }
}
</script>

<style scoped>
.table-wrap {
  min-height: 500px;
}
.button-size {
  width: 10%;
  height: 45px;
  position: relative;
  left: 46.5%;
  bottom: 60px;
}

.help-board-top {
  width: 100%;
  text-align: right;
  padding-bottom: 15px;
}

.help-board-footer {
  height: 50px;
  text-align: center;
}

.payment-table-text {
  letter-spacing: -0.48px;
  font-weight: 400;
  text-align: center;
}

/*.help-items {
  display: -webkit-box;
  word-wrap: break-word;ㅅ
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  max-height: 40px;
  height: 100%;
  letter-spacing: -0.48px;
  font-weight: 400;
}*/
</style>