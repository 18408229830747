<template>
  <b-modal id="board-remove-modal" size="sm" class="w-50" hide-footer hide-header centered>
    <section class="logout-modal-body">
      <b-row class="text-center">
        <b-col class="pb-2 nanumgothic-choice-gray-15px" cols="12"> 도움말을 삭제하시겠습니까? </b-col>
      </b-row>
    </section>
    <section class="w-100 d-flex mt-4">
      <button class="close-button button-height custom-mr-10" @click="cancel">취소</button>
      <button class="warning-button button-height" @click="remove">삭제</button>
    </section>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class HelpDeleteModal extends Vue {
  @Prop() listId?: string;
  @Prop() category?: string;

  async remove(): Promise<void> {
    try {
      const { data } = await this.axios.delete(`/board/help-delete/${this.listId}`);
      const { result } = data;
      if (result) {
        this.$common.makeToast(ToastMessage.DELETE, ToastVariant.DANGER, this.$bvToast);
        await this.$router.push(`/admin/qna`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  cancel() {
    this.$bvModal.hide('board-remove-modal');
  }
}
</script>

<style scoped>
.button-height {
  height: 40px;
}
</style>
