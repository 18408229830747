<template>
  <section class="mx-auto">
    <router-view />
    <div class="py-4" />
    <b-overlay :show="loading">
      <div>
        <div class="d-flex justify-content-between">
          <h5>Namespaces</h5>
          <div>
            <b-button-group>
              <b-button variant="primary" size="sm" @click="showCreateNamespaceModal = true">
                <b-icon-plus />
              </b-button>
              <b-button variant="primary" size="sm" @click="load">
                <b-icon-arrow-clockwise />
              </b-button>
            </b-button-group>
            <b-modal
              title="Create Namespace"
              v-model="showCreateNamespaceModal"
              @ok="createNameSpace"
              :ok-title="'저장'"
              centered
              cancel-title="취소"
            >
              <b-form-group label="" label-for="namespace-name">
                <b-form-input
                  id="namespace-name"
                  v-model="nameSpaceModel"
                  aria-describedby="namespace-name-live-feedback"
                />
              </b-form-group>
            </b-modal>
          </div>
        </div>
        <div
            v-for="{ metadata, status: { phase } } in items" :key="metadata.uid"
            class="border-bottom d-flex justify-content-between p-2"
        >
          <div>
            <router-link :to="{  path: `/admin/k8s/apps/${ metadata.name }`, }">
              <small :class="namespace === metadata.name ? 'font-weight-bold' : ''">{{ metadata.name }}</small>
            </router-link>
          </div>
          <div class="d-flex">
            <b-button-group
                v-if="namespace === metadata.name"
            >
              <b-button variant="warning" size="sm" v-b-modal="metadata.uid">
                <b-icon-zoom-in />
              </b-button>
              <b-modal
                  :title="metadata.name"
                  :id="metadata.uid"
                  centered
                  size="xl"
                  hide-footer
              >
                <vue-json-pretty
                    :data="metadata"
                    :height="300"
                    :showLength="true"
                    :showLineNumber="true"
                    :showIcon="true"
                    :virtual="true"
                />
              </b-modal>
              <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteNamespace(metadata.name)"
              >
                <b-icon-trash />
              </b-button>
            </b-button-group>
            <div class="px-1" />
            <div>
              <b-badge :variant="phase === 'Active' ? 'success' : phase === 'Terminating' ? 'danger' :  'secondary'" class="mr-2">{{ phase }}</b-badge>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Namespace, Item } from '@/interface/admin/k8s/namespace';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sNameSpace extends Vue {
  private loading = false;
  private showCreateNamespaceModal = false;
  private nameSpaceModel: string | null = null;
  private items: Item[] = [];

  get namespace () {
    return this.$route.params.namespace;
  }

  private async created() {
    this.load();
  }

  private async load() {
    try {
      this.loading = true;
      const url = `/admin/k8s/namespace`;
      const { data: { body: { items } } } = await this.axios.get<Namespace>(url);
      this.items = items;
    } finally {
      this.loading = false;
    }
  }

  private async createNameSpace() {
    try {
      this.loading = true;
      const url = `/admin/k8s/namespace`;
      const { data } = await this.axios.post(url, { namespace: this.nameSpaceModel });
      console.log(data);
      await this.load();
    } finally {
      this.loading = false;
    }
  }

  private async deleteNamespace(namespace: string) {
    try {
      this.loading = true;
      const url = `/admin/k8s/namespace`;
      const { data: { body: { items } } } = await this.axios.delete<Namespace>(url, { data: { namespace } });
      this.items = items;
    } finally {
      this.loading = false;
    }
  }

}

</script>

<style scoped>
  section {
    max-width: 960px;
  }
</style>
