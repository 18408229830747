<template>
  <section class="mx-auto">
    <router-view />
    <div class="py-4" />
    <b-overlay :show="loading">
      <div class="d-flex justify-content-between">
        <h5>Ingress</h5>
        <div>
          <b-button-group>
            <b-button variant="primary" size="sm" @click="load">
              <b-icon-arrow-clockwise />
            </b-button>
          </b-button-group>
        </div>
      </div>
      <div v-for="item in items" :key="item.metadata.uid" class="border-bottom d-flex justify-content-between align-content-start align-items-start p-2">
        <div class="d-flex justify-content-between">
          <div>
            <router-link :to="{ path: `/admin/k8s/networks/${ item.metadata.namespace }/${ item.metadata.name }` }">
              {{ item.metadata.name }}
            </router-link>
          </div>
          <div class="px-1" />
          <div>
            <b-badge variant="danger">
              {{ item.metadata.namespace }}
            </b-badge>
          </div>
        </div>
        <div>
          <b-button-group>
            <b-button variant="warning" size="sm" v-b-modal="`modal-${item.metadata.uid}`">
              <b-icon-zoom-in />
            </b-button>
            <b-modal
                :title="item.metadata.name"
                :id="`modal-${item.metadata.uid}`"
                centered
                size="xl"
                hide-footer
            >
              <vue-json-pretty
                  :data="item"
                  :height="300"
                  :showLength="true"
                  :showLineNumber="true"
                  :showIcon="true"
                  :virtual="true"
              />
            </b-modal>
          </b-button-group>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Ingress, Item } from '@/interface/admin/k8s/ingress';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sIngress extends Vue {
  private loading = false;
  private items: Item[] = [];

  created() {
    this.load();
  }

  async load() {
    try {
      this.loading = true;
      this.items = await this.getIngresses();
      console.log(this.items);
    } finally {
      this.loading = false;
    }
  }

  async getIngresses() {
    const url = `/admin/k8s/ingress`;
    const { data: { body: { items } } } = await this.axios.get<Ingress>(url);
    return items;
  }

}

</script>

<style scoped>

</style>
