<template>
  <div>
    <router-view />

    <main class="sub-layout-container mb-3">
      <article class="sub-layout-top-container">
        <section class="sub-items-title-wrapper">
          <uni-font  color="textTitle" size="30" weight="bold">프로젝트 리스트</uni-font>
        </section>
        <b-overlay spinner-variant="primary" :show="loading" rounded="sm" class="sub-layout-body-container">
          <article class="w-100">
            <b-row class="mt-3">
              <section class="help-board-top d-flex align-items-center">
                <div class="pl-3 d-flex align-items-center">
                  <b-form-datepicker
                    id="example-datepickerStart"
                    class="date-picker"
                    v-model="searchDateStart"
                    size="sm"
                    @input="changeFilter({ searchDateStart })"
                  />
                  <div class="mx-1">~</div>
                  <b-form-datepicker
                    id="example-datepickerEnd"
                    class="date-picker"
                    v-model="searchDateEnd"
                    size="sm"
                    @input="changeFilter({ searchDateEnd })"
                  />
                </div>

                <div class="ml-auto d-flex align-items-center">
                  <select class="my-survey-select-box" size="sm" v-model="searchType" @change="search = ''">
                    <option
                      v-for="(option, index) of searchOptions"
                      class="nanumgothic-bold-granite-gray-13px testCss"
                      :value="option.value"
                      :key="index"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                  <div v-if="searchType === 'LINK_STATUS'">
                    <b-form-select
                      class="status-selector"
                      v-model="search"
                      :options="statusOptions"
                      @change="searchSubmit"
                      size="sm"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled> 상태값을 선택해 주세요 </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                  <div v-else class="d-flex">
                    <uni-button :disabeld="loading" variant="icon-button" :click="searchSubmit" class="search-button-icon">
                      <search-icon :active="searchButtonHover" />
                    </uni-button>
                    <input
                      class="search-bar-wrapper"
                      type="text"
                      placeholder="프로젝트 검색"
                      v-model="search"
                      @focusin="searchButtonHover = true"
                      @focusout="searchButtonHover = false"
                      @keyup.enter="searchSubmit"
                    />
                  </div>
                </div>
              </section>
              <b-col cols="12" class="table-wrap">
                <b-table-simple class="w-100 list-table" hover responsive>
                  <colgroup>
                    <col width="5%"/>
                    <col width="10%"/>
                    <col width="6%"/>
                    <col width="5%"/>
                    <col width="26%"/>
                    <col width="11%"/>
                    <col width="5%"/>
                    <col width="4%"/>
                    <col width="11%"/>
                    <col width="23%"/>
                  </colgroup>
                  <b-thead>
                    <b-tr>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">NO</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">의뢰자ID</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">설문유형</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">UNIID</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">프로젝트명</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">진행상태</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">결제유형</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">결제금액</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">결제상태</uni-font>
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold">조사기간</uni-font>
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <tbody v-if="loading">
                  <tr v-for="i in 10" :key="i">
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                    <th class="pl-3 pr-3">
                      <b-skeleton/>
                    </th>
                  </tr>
                  </tbody>
                  <b-tbody v-else>
                    <template v-if="questionList.length">
                      <b-tr
                        v-for="(list, index) in questionList"
                        :key="`list-${index}`"
                        @click="redirectProjectDetail(list.SNUM)"
                        class="pointer"
                      >
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ list.CONFIG.SIMPLE_SURVEY_INDEX }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <div v-for="(owner, index) in list.ROLE.OWNER"
                               :key="index"
                          >
                            {{
                              list.ROLE.OWNER.length >1?
                                owner!=='SI***********'? owner: ''
                                :owner
                            }}
                          </div>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                          {{ list.CONFIG.SIMPLE_SURVEY_TYPE === 1 ? '패널' : '리스트' }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ list.SNUM }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ list.TITLE || '제목 없음' }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ list.STATUS }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                          {{ list.CONFIG.PAYMENT_INFO ? list.CONFIG.PAYMENT_INFO.data.method : '-' }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                          {{ list.CONFIG.PAYMENT_INFO
                          ? list.CONFIG.LINK_STATUS === 4
                            ? numberComma(list.CONFIG.PAYMENT_INFO.data.cancelled_price)
                            : numberComma(list.CONFIG.PAYMENT_INFO.data.price)
                          : 0 }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ list.STATUS }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                          {{ list.CONFIG.OPEN_START_DT }} ~ {{ list.CONFIG.OPEN_END_DT }}
                          </uni-font>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td colspan="9">
                          <uni-font color="textTitle" size="14">
                            검색된 프로젝트 리스트가 없습니다.
                          </uni-font>
                        </b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-pagination-nav
              use-router
              align="center"
              v-model="page"
              :number-of-pages="numberOfPages"
              :limit="7"
              :link-gen="linkGen"
            />
            <div class="w-100">
              <uni-button
                variant="option"
                :click="xlsxDownload"
              >
                <uni-font color="placeholder">다운로드</uni-font>
              </uni-button>

            </div>
          </article>
        </b-overlay>
      </article>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ProjectListSkeleton from '@/components/board/ProjectListSkeleton.vue';
import moment from 'moment';
import { Dictionary } from 'vue-router/types/router';
import { QUESTION } from "@/types/question";
import SearchIcon from "@/components/icons/common/SearchIcon.vue";

interface OWNER {
  OWNER: string[]
}

interface IProjectList {
  ROLE: OWNER,
  CONFIG: any
  SNUM: number,
  TITLE: string,
  isUse: boolean,
  regDate: Date,
  _id: string,
  STATUS: string
}

@Component({
  components: {
    SearchIcon,
    ProjectListSkeleton,
  },
})
export default class ProjectList extends Vue {
  page = 1;
  numberOfPages = 1;
  limitPage = 10;
  search = '';
  searchType = 'TITLE';
  searchDateStart = '';
  searchDateEnd = '';
  loading = false;
  searchButtonHover = false;

  searchOptions = [
    { value: 'TITLE', text: '프로젝트명' },
    { value: 'snum', text: 'UNIID' },
    { value: 'OWNER', text: '의뢰자 ID' },
    { value: 'USERNAME', text: '사용자 이름' },
    { value: 'LINK_STATUS', text: '진행상태' },
  ];

  statusOptions = [
    { value: QUESTION.LINK_STATUS.DEFAULT , text: '링크생성전' },
    { value: QUESTION.LINK_STATUS.TEST , text: '테스트 링크 생성' },
    { value: QUESTION.LINK_STATUS.REAL , text: '링크 확정' },
    /*{ value: QUESTION.LINK_STATUS.PAID , text: '결제완료' },*/
    { value: QUESTION.LINK_STATUS.REVIEW_HOLD , text: '검수 대기' },
    { value: QUESTION.LINK_STATUS.REVIEW , text: '검수중' },
    { value: QUESTION.LINK_STATUS.REVIEW_DONE , text: '검수 완료' },
    { value: QUESTION.LINK_STATUS.REJECT , text: '반려' },
    { value: QUESTION.LINK_STATUS.REFUND , text: '환불' },
    { value: QUESTION.LINK_STATUS.FW_START , text: '진행중' },
    { value: QUESTION.LINK_STATUS.FW_END , text: '진행완료' },
    { value: QUESTION.LINK_STATUS.FW_PAUSE , text: '중단' },
    { value: QUESTION.LINK_STATUS.FW_CANCEL , text: '취소' },
    { value: QUESTION.LINK_STATUS.FW_SHUT_DOWN , text: '종료' },
  ];

  limitOptions = [
    { value: 10, text: '10개씩 보기' },
    { value: 20, text: '20개씩 보기' },
    { value: 30, text: '30개씩 보기' },
    { value: 40, text: '40개씩 보기' },
    { value: 50, text: '50개씩 보기' },
  ];
  questionList: IProjectList[] = [];

  async created() {
    this.init();
    await this.load();
  }

  init() {
    const { page, search, searchType, searchDateStart, searchDateEnd, limitPage } = this.$route
      .query as Dictionary<string>;

    if (page) this.page = +page;
    if (search) this.search = search;
    if (searchType) this.searchType = searchType;
    if (searchDateStart) this.searchDateStart = searchDateStart;
    if (searchDateEnd) this.searchDateEnd = searchDateEnd;
    if (limitPage) this.limitPage = +limitPage;
  }

  async load(): Promise<void> {
    this.loading = true;

    try {
      const params = {
        pageNum: this.page,
        perPage: this.limitPage,
        searchType: this.searchType,
        search: this.search,
        searchDateStart: this.searchDateStart,
        searchDateEnd: this.searchDateEnd,
      };

      const { data } = await this.axios.get('/admin/project', {
        params,
      });

      const { result, list, numberOfPages } = data;

      if (result) {
        this.questionList = list.map((r) => {
          const { CONFIG, regDate } = r;
          const { LINK_STATUS } = CONFIG;

          let STATUS = '';
          switch (LINK_STATUS) {
            case -1:
              STATUS = '링크 생성 전';
              break;
            case 0:
              STATUS = '테스트링크생성';
              break;
            case 1:
              STATUS = '링크확정';
              break;
            case 2 :
              STATUS = '결제완료';
              break;
            case 201:
              STATUS = '검수대기';
              break;
            case 202:
              STATUS = '검수중';
              break;
            case 203:
              STATUS = '검수완료';
              break;
            case 3:
              STATUS = '반려';
              break;
            case 4:
              STATUS = '환불';
              break;
            case 101:
              STATUS = '진행중';
              break;
            case 102:
              STATUS = '진행완료';
              break;
            case 103:
              STATUS = '중단';
              break;
            case 104:
              STATUS = '취소';
              break;
            case 105:
              STATUS = '강제종료';
              break;
          }

          return {
            ...r,
            STATUS,
            regDate: this.dateFormat(new Date(regDate)),
          };
        });
        this.numberOfPages = numberOfPages;
      }
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }


  linkGen(page: number) {
    return this.makeQuery({ page });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp)
      .map((entry) => entry.join('='))
      .join('&');

    return `?${query}`;
  }

  changeFilter(filterValue: { [key: string]: string | number }) {
    const query = this.makeQuery({
      page: 1,
      ...filterValue,
    });
    this.$router.push(query).catch(() => ({}));
  }

  searchSubmit() {
    this.changeFilter({
      search: this.search,
      searchType: this.searchType,
    });
  }

  isSelected(snum: number) {
    const { id } = this.$route.params;
    return !isNaN(+id) ? +id === snum : false;
  }


  numberComma(number) {
    return new Intl.NumberFormat().format(number);
  }

  redirectProjectDetail(SNUM: number) {
    this.$router.push({ path: `/admin/list/${SNUM}`, query: this.$route.query })
  }

  dateFormat(date: Date): string {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }

  async xlsxDownload(){
    this.loading = true;
    try{
      const params = {
        pageNum: this.page,
        perPage: this.limitPage,
        searchType: this.searchType,
        search: this.search,
        searchDateStart: this.searchDateStart,
        searchDateEnd: this.searchDateEnd,
      };

      const data = await this.axios({
        url: '/admin/project/xlsx',
        method: 'GET',
        params: params,
        responseType: 'blob',
      }).then((response)=>{
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', '프로젝트 리스트.xlsx')
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
      })
    }catch (e) {
      console.log(e)
    }finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
.table-wrap {
  min-height: 500px;
}

.help-board-top {
  width: 100%;
  text-align: right;
  padding-bottom: 15px;
}

.help-board-footer {
  height: 50px;
  text-align: center;
}

.my-survey-select-box {
  color: $textBody;
  font-family: $nanumGothic;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.42px;
  margin-top: -1px;
  margin-right: 20px;
  width: fit-content;
  text-align: center;
  height: 28px;
  border-radius: 17px;
  border: 1px solid $line;
  padding: 0 8px;

  &:focus {
    border-color: $point-color;
  }

  &:hover {
    cursor: pointer;
  }
}

.selected td {
  font-weight: bold;
}

.list-table td {
  font-size: 0.8rem;
  padding: 10px;
}

.date-picker {
  width: 220px;
  text-align: center;
}

.status-selector {
  width: 346px;
  height: auto;
  border-radius: 17px;
  padding: 6px 32px 6px 14px;
  margin-right: 23px;
}
.title-link {
  word-break: break-all;
}

.search-button-icon {
  position: relative;
  left: 91%;
}
</style>
