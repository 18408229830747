<template>
  <div>
    <main class="sub-layout-container">
      <article class="sub-layout-top-container">
        <section class="sub-items-title-wrapper">
          <span class="sub-items-title"
            >{{ tabIndex === 0 ? "공지사항" : "도움말" }} 등록</span
          >
        </section>
        <article class="sub-layout-body-container">
          <section class="w-100">
            <table class="table-container">
              <tr class="nanumgothic-normal-fuscous-gray-14px">
                <th>제목</th>
                <td>
                  <b-form-input
                    v-model="help.TITLE"
                    class="nanumgothic-bold-silver-chalice-14px input-focus-bg"
                    placeholder="제목을 입력해주세요"
                  />
                </td>
              </tr>
              <tr class="nanumgothic-normal-fuscous-gray-14px">
                <th>카테고리</th>
                <td class="text-left">
                  <b-form-select
                    v-model="help.CATEGORY"
                    class="w-25"
                    :options="HELP_CATEGORY_OPTION"
                  />
                </td>
              </tr>
              <tr class="nanumgothic-normal-fuscous-gray-14px">
                <th rowspan="2">내용</th>
                <td class="help-board-table">
                  <VueEditor
                    id="help-editor"
                    v-model="help.CONTENTS"
                    :editorToolbar="customToolbar"
                    @image-added="handleImageAdded"
                    @image-removed="handleImageRemove"
                    :key="uploadImages.length"
                    useCustomImageHandler
                  />
                </td>
              </tr>
              <tr class="nanumgothic-normal-fuscous-gray-14px">
                <td class="text-left">
                  <ImageUpload @uploadResult="imageAppend" />
                </td>
              </tr>
              <tr class="nanumgothic-normal-fuscous-gray-14px">
                <th>태그</th>
                <td class="help-board-table">
                  <div>
                    <b-badge
                      class="mr-1"
                      v-for="(tag, tagIndex) in help.TAG"
                      :key="`tag-${tagIndex}`"
                      variant="primary"
                    >
                      # {{ tag }}
                    </b-badge>
                  </div>
                  <b-input-group size="sm">
                    <b-form-input
                      v-model="tag"
                      placeholder="태그를 입력해주세요"
                      @keyup.enter="addTag"
                      ref="tag"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="addTag"
                        v-b-tooltip.hover.top="`태그 등록`"
                      >
                        <font-awesome-icon :icon="['fas', 'plus']" />
                        등록
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </td>
              </tr>
            </table>
          </section>
        </article>
        <b-row class="mt-3 w-100" style="max-width: 1200px !important">
          <b-col cols="11">
            <div class="d-flex justify-content-end">
              <uni-button
                class="custom-mr-16"
                variant="secondary-outline"
                :click="
                  () =>
                    $router.push({
                      name: 'qna-list',
                      query: { tabIndex: this.tabIndex },
                    })
                "
              >
                <uni-font color="placeholder" size="14">목록</uni-font>
              </uni-button>

              <uni-button :click="save">
                <uni-font color="white" size="14"> 등록 </uni-font>
              </uni-button>
            </div>
          </b-col>
        </b-row>
      </article>
    </main>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {
  helpInit,
  IHelp,
  HELP_CATEGORY_OPTION,
  HELP_CATEGORY,
} from "@/types/board";
import { VueEditor } from "vue2-editor";
import ImageUpload from "@/components/question/ImageUpload.vue";
import { ToastMessage, ToastVariant } from "@/utils/ToastEnum";

@Component({
  components: {
    VueEditor,
    ImageUpload,
  },
})
export default class AdminHelpWrite extends Vue {
  loading = false;
  help: IHelp = helpInit(this.$route.query.tabIndex !== "0");
  tag = "";
  tabIndex = this.$route.query.tabIndex;

  customToolbar: any[] = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["image", "code-block"],
  ];
  dataUrlList: string[] = [];
  uploadImages: File[] = [];
  path = location.origin + "/uploads/Question/";
  file = [];
  /*HELP_CATEGORY_OPTION = this.$route.query.tabIndex > 0?
      HELP_CATEGORY_OPTION.filter((r, index) => r.text !== HELP_CATEGORY.NOTICE)
      :HELP_CATEGORY_OPTION.filter((r, index)=> r.text === HELP_CATEGORY.NOTICE);
*/
  HELP_CATEGORY_OPTION =
    this.$route.query.tabIndex !== '0'
      ? HELP_CATEGORY_OPTION.filter(
          (r, index) => r.text !== HELP_CATEGORY.NOTICE
        )
      : HELP_CATEGORY_OPTION.filter(
          (r, index) => r.text === HELP_CATEGORY.NOTICE
        );

  $refs: Vue["$refs"] & {
    formFile: HTMLFormElement;
    tag: HTMLElement;
  };

  async save(): Promise<void> {
    try {
      const { TITLE, CONTENTS, TAG, CATEGORY } = this.help;
      if (TITLE.length < 2) {
        this.$common.makeToast(
          ToastMessage.EMPTY_TITLE,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      } else if (CONTENTS.length < 5) {
        this.$common.makeToast(
          ToastMessage.EMPTY_CONTENT,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      }

      const isFiles = this.dataUrlList.length;
      const replacerContent = isFiles ? this.getReplacer(CONTENTS) : CONTENTS;
      const formData: FormData = new FormData();

      this.uploadImages.forEach((file, fileIdx) => {
        formData.append("fileList", file);
      });
      const sendData = {
        title: TITLE,
        category: CATEGORY,
        contents: replacerContent,
        tag: TAG,
      };

      const { data } = await this.axios.post(`/board/help-write`, sendData);
      if (this.dataUrlList.length) {
        const { _id } = data;
        await this.uploadFile(_id, formData);
      }

      const { result } = data;

      if (result) {
        this.$common.makeToast(
          ToastMessage.WRITE_HELP_BOARD,
          ToastVariant.SUCCESS,
          this.$bvToast
        );
        await this.$router.push("/admin/qna");
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadFile(_id: string, formData: FormData) {
    const { data } = await this.axios.post(
      `/board/help-write/${_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  private imageAppend(data: { file: File; dataUrl: string }) {
    this.uploadImages.push(data.file);
    this.dataUrlList.push(data.dataUrl);
    this.help.CONTENTS += `<img src="${data.dataUrl}" style="max-width: 360px">`;
  }

  getReplacer(content: string) {
    if (this.dataUrlList.length) {
      this.dataUrlList.forEach((dataUrl, dataIdx) => {
        content = content.replace(dataUrl, `#ReplaceImage.${dataIdx}`);
      });
    }
    return content;
  }

  addTag(): void {
    if (this.tag == "") {
      this.$common.makeToast(
        ToastMessage.EMPTY_TAG,
        ToastVariant.DANGER,
        this.$bvToast
      );
      this.$refs.tag.focus();
      return;
    } else if (this.tag.indexOf(" ") > -1) {
      this.$common.makeToast(
        ToastMessage.NOT_BLANK_TAG,
        ToastVariant.DANGER,
        this.$bvToast
      );
      this.$refs.tag.focus();
      return;
    } else if (this.help.TAG.indexOf(this.tag) > -1) {
      this.$common.makeToast(
        ToastMessage.SAME_TAG,
        ToastVariant.DANGER,
        this.$bvToast
      );
      this.$refs.tag.focus();
      return;
    }
    this.help.TAG.push(this.tag);
    this.tag = "";
  }

  handleImageRemove(dataUrl: string) {
    const idx = this.dataUrlList.indexOf(dataUrl);
    if (idx >= 0) {
      this.dataUrlList.splice(idx, 1);
      this.uploadImages.splice(idx, 1);
    }
  }

  async handleImageAdded(file: File, Editor, cursorLocation, resetUploader) {
    if (!file) return;
    const isImage = this.isImage(file);
    if (isImage) {
      const dataUrl = await this.getDataUrl(file);
      const sendData = {
        file,
        dataUrl,
      };
      this.imageAppend(sendData);
    }
  }

  isImage(file: File): boolean {
    const { type } = file;
    const typeList = ["jpg", "jpeg", "png"];

    const [image, imageType] = type.split("/");
    if (image !== "image" || !typeList.includes(imageType)) {
      this.$common.makeToast(
        ToastMessage.IMAGE_TYPE,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    } else {
      return true;
    }
  }

  async getDataUrl(file: File): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(reader.result + "");
    });
  }
}
</script>

<style scoped>
.border-line {
  border: 1px solid var(--line);
}
</style>
