<template>
  <b-overlay :show="loading" class="w-100">
    <div
        class="d-flex justify-content-between p-2 w-100"
    >
      <router-link :to="{  path: `/admin/k8s/apps/${ namespace }/${ deployment }/${ metadata.name }` }">
        <small>{{ metadata.name }}</small>
      </router-link>
      <div class="d-flex">
        <div>
          <b-badge variant="success">
            hostIP: {{ hostIP }}
          </b-badge>
        </div>
        <div class="px-1" />
        <div>
          <b-badge :variant="phase === 'Running' ? 'success' : phase === 'Terminating' ? 'danger' :  'secondary'" class="mr-2">{{ phase }}</b-badge>
        </div>
        <div class="px-1" />
        <div>
          <b-badge variant="success">
            podIPs: {{ podIPs.map(({ ip }) => ip).join(',') }}
          </b-badge>
        </div>
        <div class="px-1" />
        <b-button-group>
          <b-button variant="warning" size="sm" @click="modal = true">
            <b-icon-zoom-in />
          </b-button>
          <b-modal
              :title="metadata.name"
              :id="metadata.uid"
              v-model="modal"
              centered
              size="xl"
              hide-footer
          >
            <vue-json-pretty
                :data="metadata"
                :height="300"
                :showLength="true"
                :showLineNumber="true"
                :showIcon="true"
                :virtual="true"
            />
          </b-modal>
        </b-button-group>
      </div>
    </div>
    <!-- usage  -->
    <div class="d-flex justify-content-end">
      <b-badge
          variant="success"
          v-for="([ cpu, memory ], idx) in usage"
          :key="`${ metadata.name }-${ idx }`"
      >
        <b-icon-cpu />
        {{ cpu }} / {{ memory }}
      </b-badge>
    </div>
  </b-overlay>
</template>

<script lang="ts">
import { Component, Ref, Vue, Prop } from 'vue-property-decorator';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { Pod, BodyItem , PodIP, ItemMetadata } from '@/interface/admin/k8s/pod';
import { PodUsage, Container } from '@/interface/admin/k8s/pod.usage';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sPodComponent extends Vue {
  @Prop() namespace!: string;
  @Prop() deployment!: string;
  @Prop() metadata!: ItemMetadata;
  @Prop() hostIP!: string;
  @Prop() phase!: string;
  @Prop() podIPs!: PodIP[];
  private loading = false;
  private containers: Container[] = [];
  private modal = false;

  get podName() {
    return this.metadata.name;
  }

  async created() {
    this.containers = await this.getUsage();
  }

  get usage() {
    return this.containers.map(({ usage: { cpu, memory } }) => [ `cpu: ${ cpu }`, `mem: ${ memory }` ])
  }

  async getUsage() {
    try {
      this.loading = true;
      const url = `/admin/k8s/pod/${this.namespace}/${ this.deployment }/${ this.podName }/top`;
      const { data: { containers, metadata } } = await this.axios.get<PodUsage>(url);
      console.log(containers, metadata);
      return containers;
    } finally {
      this.loading = false;
    }
  }

}

</script>

<style scoped>
section {
  max-width: 960px;
}
</style>
