<template>
  <article class="sub-layout-body-container custom-mt-20">
    <section class="w-100">
      <h1 class="h5 user-setting-title-wrapper">
        <b-icon icon="list-ul" class="mr-2" />
        <span class="text nanumgothic-bold-fuscous-gray-20px">프로젝트 내역</span>
      </h1>
      <hr class="hr" />
    </section>
    <section class="w-100">
      <table class="payment-table">
        <colgroup>
            <col width="5%"/>
            <col width="10%"/>
            <col width="35%"/>
            <col width="15%"/>
            <col width="35%"/>
          </colgroup>
        <thead>
          <tr>
            <th class="payment-table-text nanumgothic-normal-fuscous-gray-16px" >NO</th>
            <th class="payment-table-text nanumgothic-normal-fuscous-gray-16px" >프로젝트 번호</th>
            <th class="payment-table-text nanumgothic-normal-fuscous-gray-16px" >프로젝트명</th>
            <th class="payment-table-text nanumgothic-normal-fuscous-gray-16px" >결제 금액</th>
            <th class="payment-table-text nanumgothic-normal-fuscous-gray-16px" >조사기간</th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr v-for="i in 3" :key="i">
            <th class="pl-3 pr-3"><b-skeleton /></th>
            <th class="pl-3 pr-3"><b-skeleton /></th>
            <th class="pl-3 pr-3"><b-skeleton /></th>
            <th class="pl-3 pr-3"><b-skeleton /></th>
            <th class="pl-3 pr-3"><b-skeleton /></th>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-if="paymentHistory.length === 0">
            <td colspan="24" class="text-center nanumgothic-normal-granite-gray-16px">
              프로젝트 내역이 존재하지 않습니다.
            </td>
          </tr>
          <tr v-for="(payInfo, payIdx) in paymentHistory" :key="`pay-${payIdx}`">
            <td class="payment-table-text nanumgothic-normal-granite-gray-16px">{{ payIdx+1 }}</td>
            <td class="payment-table-text nanumgothic-normal-granite-gray-16px">{{ payInfo.projectId }}</td>
            <td class="payment-table-text nanumgothic-normal-granite-gray-16px text-left">
              <router-link :to="{ path: `/admin/list/${payInfo.projectId}`, query: $route.query }" class="title-link">
                {{ payInfo.projectTitle }}
              </router-link>
            </td>
            <td class="payment-table-text nanumgothic-normal-granite-gray-16px">{{ payInfo.price }}</td>
            <td class="payment-table-text nanumgothic-normal-granite-gray-16px">
              <div class="payment-status">
                  <uni-font color="textBody" size="14">
                      {{ payInfo.OPEN_START_DT}} ~ {{ payInfo.OPEN_END_DT}}
                  </uni-font>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
<!--      <b-pagination-nav
        class="custom-mt-10"
        align="center"
        use-router
        :limit="7"
        :link-gen="linkGen"
        :number-of-pages="totalPage"
        v-model="page"
      />-->
      <b-pagination
          align="center"
          v-model="page"
          :total-rows="totalPage"
          :per-page="3"
          @input="getPaymentLists"
      ></b-pagination>
    </section>
  </article>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import '@/assets/css/component/layout/user-setting.css';
import moment from 'moment';
import { Dictionary } from 'vue-router/types/router';

@Component({ components: {} })
export default class ProjectInfo extends Vue {
  paymentHistory: {
    projectId: number;
    projectTitle: string;
    price: string;
    purchasedDate: string;
    receiptUrl: string;
    status: number;
    OPEN_START_DT: string;
    OPEN_END_DT: string;
  }[] = [];
  page = 1;
  totalPage = 1;
  loading = true;

  created() {
    this.init();
    this.getPaymentLists();
  }

  private async init() {
    const { payPage } = this.$route.query as Dictionary<string>;
    if (payPage) this.page = +payPage;
  }

  private async getPaymentLists() {
    const { data: result } = await this.axios.get(`/admin/user/payment/lists/${this.$route.params.id}/${this.page}`);
    const { totalPage, list } = result;
    this.totalPage = totalPage;
    this.paymentHistory = list.map((value) => {
      const { status, data, projectId, OPEN_START_DT, OPEN_END_DT } = value;
      const { price, order_name, purchased_at, receipt_url } = data;
      // const convertPrice = (price + ' 원').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      const convertPrice = (price + ' 원').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const dateFormat = moment(purchased_at).format('YYYY-MM-DD hh:mm:ss');
      return {
        projectId: projectId,
        projectTitle: order_name,
        price: convertPrice,
        purchasedDate: dateFormat,
        receiptUrl: receipt_url,
        status,
        OPEN_START_DT,
        OPEN_END_DT
      };
    });
    this.loading = false;
  }

  private showReceipt(url: string): void {
    window.open(url, '', 'height=955, width= 700, top=150, left=150, toolbar=no, resizeable=no');
  }

  // 페이징 처리
  linkGen(page: number) {
    const { query } = this.$route;
    delete query.payPage;
    const q = Object.entries(query)
      .map((entry) => entry.join('='))
      .join('&');
    return `${this.$route.path}?${q}&payPage=${page}`;
  }

  // 결제 상태 CSS
  getPayStatusClass(status: number): string {
    if (status === 1) return 'completePaymentLabel';
    if (status === 20) return 'cancelCompletePaymentLabel';
    return '';
  }
  // 결제 상태 라벨
  payMessage(status: number): string {
    let message = '';
    if (status === 1) message = '결제 완료';
    if (status === 20) message = '결제 취소';
    return message;
  }

  get rows() {
    return this.paymentHistory.length;
  }

  get userId() {
    return this.$route.params.id;
  }
}
</script>

<style scoped>
.payment-table {
  width: 100%;
  min-height: 130px;
}

.receipt-button {
  letter-spacing: -0.42px;
  min-height: 16px;
}

.receipt-button:hover {
  color: #4d4949;
  text-underline: #a6a6a6;
  text-decoration: underline;
  text-underline-position: under;
  transition: width 0.5s, height 0.5s, background-color 0.5s, transform 0.5s;
}

.payment-status {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  justify-items: center;
  display: inline-block;
}

.payment-table-text {
  letter-spacing: -0.48px;
  min-height: 18px;
  min-width: 45px;
  text-align: center;
  font-weight: 400;
}

/* 결제 상태에 따른 태그 */
.completePaymentLabel,
.cancelCompletePaymentLabel {
  border: 1px solid;
  min-width: 55px;
  min-height: 26px;
  text-align: center;
  border-radius: 17px;
  padding: 3px 8px;
}

.completePaymentLabel {
  border-color: var(--picton-blue);
  color: var(--picton-blue);
}

.cancelCompletePaymentLabel {
  border-color: var(--textplaceholder);
  color: var(--textplaceholder);
}
.title-link {
  word-break: break-all;
}
</style>
