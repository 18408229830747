<template>
  <tbody>
      <b-tr>
        <b-td style="width: 30%;">
          <uni-font color="textTitle" size="15" weight="medium">개인정보</uni-font>
        </b-td>
        <b-td>
            <div class="d-flex">
                <b-form-radio
                  class="mr-5"
                  v-model="privateInfo"
                  name="private-radios"
                  disabled
                  v-b-tooltip.top="`설문 미리보기에서 변경해주세요.`"
                  :value="true"
                >해당</b-form-radio>
                <b-form-radio
                  v-model="privateInfo"
                  name="private-radios"
                  v-b-tooltip.top="`설문 미리보기에서 변경해주세요.`"
                  disabled
                  :value="false"
                >해당없음</b-form-radio>
            </div>
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <uni-font color="textTitle" size="15" weight="medium">민감정보</uni-font>
        </b-td>
        <b-td>
            <div class="d-flex">
                <b-form-radio
                  class="mr-5"
                  v-model="sensitiveInfo"
                  name="sensitive-radios"
                  disabled
                  v-b-tooltip.top="`설문 미리보기에서 변경해주세요.`"
                  :value="true"
                >해당</b-form-radio>
                <b-form-radio
                  v-model="sensitiveInfo"
                  name="sensitive-radios"
                  v-b-tooltip.top="`설문 미리보기에서 변경해주세요.`"
                  disabled
                  :value="false"
                >해당없음</b-form-radio>
            </div>
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <uni-font color="textTitle" size="15" weight="medium">정치관련</uni-font>
        </b-td>
        <b-td>
            <div class="d-flex">
                <b-form-radio
                  class="mr-5"
                  v-model="political"
                  name="political-radios"
                  v-b-tooltip.top="`설문 미리보기에서 변경해주세요.`"
                  disabled
                  :value="true"
                >해당</b-form-radio>
                <b-form-radio
                  v-model="political"
                  name="political-radios"
                  v-b-tooltip.top="`설문 미리보기에서 변경해주세요.`"
                  disabled
                  :value="false"
                >해당없음</b-form-radio>
            </div>
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <uni-font color="textTitle" size="15" weight="medium">코멘트작성</uni-font>
        </b-td>
        <b-td>
          <label class="d-flex">
            <textarea :disabled="!(sensitiveInfo || privateInfo || political)" class="textarea input-focus-bg" v-model="comment"></textarea>
            <uni-button
              width="112"
              height="40"
              :click="checkListActive"
              :disabled="isRefunded || isSample"
            >
              <uni-font :color="isRefunded ? 'placeholder' : 'white'" size="16" weight="semiBold">저장</uni-font>
            </uni-button>
          </label>
        </b-td>
      </b-tr>
  </tbody>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class ProjectViewCheckList extends Vue{
  @Prop() snum: number;
  @Prop() owner: string;
  @Prop() config: any;
  @Prop() private readonly isRefunded: boolean;
  @Prop() private readonly isSample: boolean;

  privateInfo = false;
  sensitiveInfo = false;
  political = false;
  comment = '';

  created(){
    this.radioSet();
  }

  radioSet(){
    if (this.config.SIMPLE_SURVEY_CHECKLIST) {
      const {PRIVATE_INFO, SENSITIVE_INFO, POLITICAL, COMMENT} = this.config.SIMPLE_SURVEY_CHECKLIST;
      this.privateInfo = PRIVATE_INFO;
      this.sensitiveInfo = SENSITIVE_INFO;
      this.political = POLITICAL;
      this.comment = COMMENT;
    }
  }

  async checkListActive(){
    const sendData = {
      _id: this.snum,
      privateInfo : this.privateInfo,
      sensitiveInfo : this.sensitiveInfo,
      political : this.political,
      comment : this.comment
    };
    await this.axios.post('/admin/project/checkListSave', sendData);
    await this.$store.dispatch('socketStore/unisurveyAlertUpdate',
      {
        SNUM: this.snum,
        userId: this.owner,
        ALERT_CHECK: false
      });
    this.$emit('reload');
  }
}
</script>
<style scoped lang="scss">
.textarea {
  border: 1px solid $line;
  color: $textSub;
  width: 450px;
  min-height: 120px;
  height: auto;
  overflow-y: auto;
  padding: 4px;
  font-size: 14px;
  font-family: $nanumGothic;
  margin-right: 16px;
}
</style>

