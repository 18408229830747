<template>
  <svg class="search-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3156 12.0598H12.6573L12.424 11.8348C13.424 10.6681 13.9406 9.07643 13.6573 7.38477C13.2656 5.0681 11.3323 3.2181 8.99896 2.93477C5.47396 2.50143 2.50729 5.4681 2.94063 8.9931C3.22396 11.3264 5.07396 13.2598 7.39063 13.6514C9.08229 13.9348 10.674 13.4181 11.8406 12.4181L12.0656 12.6514V13.3098L15.6073 16.8514C15.949 17.1931 16.5073 17.1931 16.849 16.8514C17.1906 16.5098 17.1906 15.9514 16.849 15.6098L13.3156 12.0598ZM8.31563 12.0598C6.24063 12.0598 4.56563 10.3848 4.56563 8.30977C4.56563 6.23477 6.24063 4.55977 8.31563 4.55977C10.3906 4.55977 12.0656 6.23477 12.0656 8.30977C12.0656 10.3848 10.3906 12.0598 8.31563 12.0598Z"
          :fill="active ? '#35B6E7' : '#8C8C8C'"/>
  </svg>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class SearchIcon extends Vue {
  @Prop({default: false}) active?: boolean;
}
</script>

<style lang="scss" scoped>
.search-icon {
  &:hover > path {
    fill: #35B6E7;
  }
}

</style>