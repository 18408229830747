<template>
  <div>
    <section class="help-board-top"/>
    <section
        v-if="tabIndex === 1"
        class="help-board-top">
      <input
        type="text"
        class="search-bar-wrapper"
        placeholder="도움말 검색"
        v-model="search"
        @focusin="searchButtonHover = false"
        @focusout="searchButtonHover = true"
        @keyup.enter="load"
      />
      <button type="button" class="search-button search-button-icon" @click="load">
        <img
          src="@/assets/images/icons/main/search-button.svg"
          @mouseover="activeSearchButton"
          alt="검색 버튼"
          v-if="searchButtonHover"
        />
        <img
          src="@/assets/images/icons/main/search-button-active.svg"
          @mouseleave="activeSearchButton"
          alt="검색 버튼"
          v-else
        />
      </button>
    </section>
    <b-col cols="12" class="table-wrap">
      <b-table-simple class="w-100" hover responsive>
        <colgroup>
          <col width="10%" />
          <col width="35%" />
          <col width="20%" />
          <col width="20%" />
          <col width="15%" />
        </colgroup>
        <b-thead>
          <b-tr>
            <b-th class="nanumgothic-normal-fuscous-gray-16px">카테고리</b-th>
            <b-th class="nanumgothic-normal-fuscous-gray-16px">제목</b-th>
            <b-th class="nanumgothic-normal-fuscous-gray-16px">등록일</b-th>
            <b-th class="nanumgothic-normal-fuscous-gray-16px">작성자</b-th>
            <b-th class="nanumgothic-normal-fuscous-gray-16px">조회수</b-th>
          </b-tr>
        </b-thead>
        <tbody v-if="loading">
          <tr v-for="i in 10" :key="i">
            <th class="pl-3 pr-3"><b-skeleton /></th>
            <th class="pl-3 pr-3"><b-skeleton /></th>
            <th class="pl-3 pr-3"><b-skeleton /></th>
            <th class="pl-3 pr-3"><b-skeleton /></th>
            <th class="pl-3 pr-3"><b-skeleton /></th>
          </tr>
        </tbody>
        <b-tbody v-else>
          <template v-if="helpList.length > 0">
            <b-tr
              v-for="(list, index) in helpList"
              :key="`list-${index}`"
              class="pointer"
              @click="helpView(index)"
            >
              <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">
                <b-badge class="ml-3" :variant="categoryVariant(list.CATEGORY)">
                  {{ list.CATEGORY || '-' }}
                </b-badge>
              </b-td>
              <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">{{ list.TITLE }}</b-td>
              <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">{{
                $moment(list.DT).format('YYYY-MM-DD HH:mm')
              }}</b-td>
              <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">{{
                list.REGIST_NAME
              }}</b-td>
              <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">
                {{list.READ_COUNT}}
              </b-td>
            </b-tr>
          </template>
          <template v-else>
            <b-tr>
              <b-td colspan="5" class="text-center payment-table-text nanumgothic-normal-granite-gray-16px"
                >검색된 도움말이 없습니다</b-td
              >
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-col>
    <b-col cols="12" class="help-board-footer">
      <b-pagination
        v-model="page"
        :total-rows="totalRow"
        :per-page="limitPage"
        @input="load"
        align="center"
      />
      <button
        class="confirm-button button-size"
        id="btn-add"
        @click="$router.replace({name: 'qna-write', query: {tabIndex: tabIndex}})"
      >
        {{ tabKey }} 등록
      </button>
    </b-col>
  </div>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";
import {IHelp, HELP_CATEGORY} from '@/types/board';

@Component({})
export default class NoticeTable extends Vue{
  @Prop() tabIndex?: number;
  tabKey = '';
  loading = true;
  helpList: IHelp[] = [];
  page = 1;
  totalRow = 0;
  limitPage = 10;
  search = '';
  searchButtonHover = true;

  async mounted() {
    await this.load();
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  async load() {
    try {
      this.tabKey = this.tabIndex === 0? '공지사항': '도움말';
      const sendData = {
        limit: this.limitPage,
        page: this.page,
        category: this.tabKey,
        search: this.search,
      };
      const { data } = await this.axios.get(`/board/select-help`, { params: sendData });
      const { result, count, list } = data;
      if (result == true) {
        this.helpList = list;
        this.totalRow = count;
      }
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  }

  get helpListLength(): number {
    return this.helpList.length;
  }

  categoryVariant(category: HELP_CATEGORY): string {
    if (category === HELP_CATEGORY.NOTICE) return 'warning';
    else if (category === HELP_CATEGORY.USE) return 'success';
    else return 'primary';
  }

  helpView(index: number) {
    const list: IHelp = this.helpList[index];
    const { _id } = list;
    this.$router.push({name: 'qna-read', params: {helpId: _id},query: {tabIndex: this.tabIndex+''}});
    //this.$router.push(`/admin/qna-read/${this.tabKey ==='공지사항'? 'notice' : 'help'}/${_id}`);
  }
}
</script>

<style scoped>
.table-wrap {
  min-height: 500px;
}
.button-size {
  width: 10%;
  height: 45px;
  position: relative;
  left: 46.5%;
  bottom: 60px;
}

.help-board-top {
  width: 100%;
  text-align: right;
  padding-bottom: 15px;
}

.help-board-footer {
  height: 50px;
  text-align: center;
}

.payment-table-text {
  letter-spacing: -0.48px;
  font-weight: 400;
  text-align: center;
}

.help-items {
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  max-height: 40px;
  height: 100%;
  letter-spacing: -0.48px;
  font-weight: 400;
}
</style>